import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { makeServerGETRequest } from '../utils/helpers'
import RowText from '../components/RowText'
import Navbar from '../components/Navbar'
import Logo from '../assets/images/Icons/Logo.png'
import downloadIcon from '../assets/images/Icons/downloadIcon.svg'
import linkIcon from '../assets/images/Icons/link.svg'
import copyIcon from '../assets/images/Icons/copyIcon.svg'
import ImageTag from '../_atomic-design/atoms/ImageTag'
import TextPill from '../_atomic-design/molecules/Others/TextPill'
import ButtonGeneralImageTransparent from '../_atomic-design/atoms/ButtonMock/ButtonGeneralImageTransparent'
import ButtonGeneralImage from '../_atomic-design/atoms/ButtonMock/ButtonGeneralImage'
import IconCircular from '../_atomic-design/atoms/IconCircular'
import facebookLogo from '../assets/images/Icons/facebookCircular.svg'
import linkedinLogo from '../assets/images/Icons/linkedinCircular.svg'
import twitterLogo from '../assets/images/Icons/twitterCircular.svg'
import shareLogo from '../assets/images/Icons/shareCircular.svg'
const imageToURI = require('image-to-data-uri')
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL

/** This is the public page where an awarded Badge is displayed. */
function ViewBadge (props) {
  /** Extract assertion id from url query parameter */
  const { id } = useParams()

  /** Used for displaying loading animation while data is being fetched from the backend server. */
  const [loading, setLoading] = useState(true)

  /** Used for display loading animation while certificate is being downloaded */
  const [downloading, setDownloading] = useState(false)

  /** Used to store awarded badge details received from backend server. */
  const [assertionData, setAssertionData] = useState(null)

  /** Used to show verified tag for the badge.
   * This is set to true when badge details are received from the backend server. */
  const [isVerfied, setIsVerified] = useState(false)

  /** Controls loading animation for badge verification request.
   * Loading Animation is shown during API is called for verification and until result of the API call is not available. */
  const [loadingAnimation, setLoadingAnimation] = useState(false)

  /** Stores Year in which Badge was awarded */
  const [issueYear, setIssueYear] = useState(null)

  /** Stores Month in which Badge was awarded */
  const [issueMonth, setIssueMonth] = useState(null)

  /** Stores link to view badge. This link is shared on FB and twitter when user clicks fb or twitter button on this page. */
  const [viewBadgeLink, setViewBadgeLink] = useState('badge.dkraftlearning.com')

  /** tweet text that will be tweeted */
  const tweetText = encodeURIComponent('Check out my new Digital Badge!!')

  /** Currently no image being shown. After implementation of generating image from backend, share that image here */
  const imageLink = encodeURIComponent('https://images.pexels.com/photos/14818545/pexels-photo-14818545.jpeg')

  /** Stores link to share digital badges to different social media */
  const [facebookLink, setFacebookLink] = useState('')
  const [linkedinLink, setLinkedinLink] = useState('')
  const [tweetLink, setTweetLink] = useState('')

  /** This function is run whenever id changes. An api call is made to backend to fetch the details of the badge
   * corresponding to id in the url. */
  useEffect(() => {
    setLoading(true)
    makeServerGETRequest('/badge/viewBadge', {
      assertionId: id
    }).then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          setAssertionData(res.data)
          setLoading(false)
        }, 2000)
      } else {
        alert('record not found')
        setLoading(false)
      }
    })
  }, [id])

  /** This function updates Linkedin Sharer link and view badge link. This is run whenever assertion data is updated */
  useEffect(() => {
    if (assertionData) {
      const issueDate = new Date(assertionData.issuedOn)
      setIssueYear(issueDate.getFullYear())
      setIssueMonth(issueDate.getMonth() + 1)
      setViewBadgeLink(`${FRONTEND_URL}/badge/${assertionData._id}`)
      setLinkedinLink(`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${assertionData.badge.name}&organizationName=${assertionData.badge.issuer.name}&issueYear=${issueYear}&issueMonth=${issueMonth}&certUrl=${FRONTEND_URL + '/badge/' + assertionData._id}&certId=${assertionData._id}`)
      setFacebookLink(`https://www.facebook.com/sharer/sharer.php?u=${viewBadgeLink}&quote=Badge%20LAB!`)
      setTweetLink(`https://twitter.com/intent/tweet?text=${tweetText}&url=${viewBadgeLink}&amp;media=${imageLink}`)
    }
  }, [viewBadgeLink, imageLink, tweetText, assertionData, issueMonth, issueYear, facebookLink, linkedinLink, tweetLink])

  /** This function makes an API call to fetch details of the badge when user clicks on verify button.
   * During the duration for which data is awaited, loading animation is displayed instead of verify button */
  function verifyButtonClickHandler () {
    setLoadingAnimation(true)
    setIsVerified(false)
    makeServerGETRequest('/badge/viewBadge', {
      assertionId: id
    }).then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          setIsVerified(true)
          setLoadingAnimation(false)
        }, 3000)
      } else {
        setLoadingAnimation(false)
      }
    })
  }

  /** @description copies the link to view badge to ClipBoard. */
  function copyToClipboardClickHandler () {
    navigator.clipboard.writeText(`${FRONTEND_URL}/badge/${assertionData._id}`)
    alert('Link Copied To Clipboard')
  }

  /**
   * @description Generates an image from html page displayed to the user. This method of downloading badge
   * image will be changed in future release. Image will be generated from the backend.
   * @update : The above written strategy has been implemented.
   */
  async function downloadBadgeImage () {
    setDownloading(true) // start displaying loading animation
    let certificateLink = assertionData.image // if assertion already contains certificate url, directly proceed to download

    /** If assertion doesn't contain url to certificate, make a request to generate a new certificate
     * and get the url of that image. */
    if (!certificateLink) {
      const response = await makeServerGETRequest('/download/certificate', { id: assertionData._id })
      if (response.data.s3ImageUrl) {
        setAssertionData((prev) => { return { ...prev, image: response.data.s3ImageUrl } })
        certificateLink = response.data.s3ImageUrl
      } else {
        alert('error downloading certificate.')
        setDownloading(false) // stop displaying loading animation
        return
      }
    }

    /**
     * Image is being converted from url to base64 because browsers don't allow
     * to download images from different origins. Our image is not stored on our servers.
     * If we directly download the image using url, image opens up in the browser, which we don't want.
     * We want to download the image to user's computer. */
    try {
      const link = document.createElement('a')
      imageToURI(certificateLink, function (err, uri) {
        if (!err) {
          link.target = '_blank'
          link.href = uri
          link.download = `${id}.png`
          link.click()
        }
      })
    } catch {
      alert('error downloading file.')
    }

    setDownloading(false) // stop displaying loading animation
  }

  return (
    <div className='flex'>
      <Navbar />
      {loading
        ? <div className='mx-auto mt-[100px] animate-spin-slow w-[100px] h-[100px]'><img src={Logo} alt='lg' className='w-full' /></div>
        : assertionData
          ? (
          <div className=' w-screen h-screen bg-white'>
            <div className='flex flex-col p-5 w-full  mx-auto relative top-[60px]'>
              <div className='flex flex-row mx-auto my-auto align-middle'>
                <div className='text-lg text-gray-500 font-bold'>
                  This Digital Badge was awarded to <p className='text-black text-3xl'>{assertionData.recipient.name}</p>
                </div>
                {loadingAnimation
                  ? <div className='animate-spin-slow h-[30px] w-[30px] my-auto mx-2'><img src={Logo} className='w-full' alt='lg' /></div>
                  : <button
                    onClick={verifyButtonClickHandler}
                    className='border w-[100px] h-[30px] mx-2 my-auto align-middle rounded-lg bg-green-300 text-green-800 flex'
                  >
                    {loadingAnimation
                      ? null
                      : <div className='mx-auto'>{isVerfied ? 'Verified' : 'Verify'}</div>}
                  </button>}
              </div>
              <TextPill textMain={`Certificate ID : ${assertionData._id}`} className={'my-2 font-bold'} />
                {downloading
                  ? <div className='animate-spin h-[30px] w-[30px] my-auto mx-auto'><img src={Logo} className='w-full' alt='lg' /></div>
                  : <ButtonGeneralImageTransparent imagePosition={'left'} image={downloadIcon} text={'Download Certificate'} className={' mx-auto font-bold '} onClick={downloadBadgeImage} />
                }
                <div id='downloadBadge' className='flex flex-col sm:flex-row relative w-full max-w-[900px] mx-auto bg-white rounded-xl my-4 justify-center shadow-4-sides '>
                  <div className='flex justify-center align-middle w-full sm:w-1/2 bg-[#F3F4F6] rounded-l-xl '>
                    <div className='my-auto mx-auto w-full'>
                    <ImageTag
                    src={assertionData.badge.image}
                    alt='digital-badge'
                    className='min-w-[300px] h-auto w-4/5 mx-auto  p-5 aspect-auto'
                    />
                  </div>
                </div>
                <div className='text-start flex flex-col w-full sm:w-1/2 rounded-lg  p-5'>
                  <RowText keyText='Recipient Name: ' valueText={assertionData.recipient.name} isVerified={isVerfied} />
                  <RowText keyText='Badge Name: ' valueText={assertionData.badge.name} isVerified={isVerfied} className={'bg-[#F6F6F6]'}/>
                  <RowText keyText='Issued By: ' valueText={assertionData.badge.issuer.name} isVerified={isVerfied} url={assertionData.badge.issuer.url} />
                  <RowText keyText='Issued On: ' valueText={new Date(assertionData.issuedOn).toLocaleDateString()} isVerified={isVerfied} className={'bg-[#F6F6F6]'}/>
                  <RowText keyText='Criteria: ' valueText={assertionData.badge.criteria} isVerified={isVerfied} url={assertionData.badge.criteria} />
                  <RowText keyText='Details: ' valueText={assertionData.narrative} isVerified={isVerfied} className={'bg-[#F6F6F6]'}/>
                </div>
                </div>

              <div className='mx-auto w-full sm:w-max flex flex-col sm:flex-row gap-x-2 gap-y-2 '>
                  {/* <ButtonGeneralImage text={`${FRONTEND_URL}/badge/${assertionData._id}`} imagePosition={'left'} image={linkIcon} className={' bg-green-300 text-green-700 font-bold mx-auto text-xs sm:text-base cursor-text break-words'} /> */}
                  <div className='flex flex-row p-1 sm:px-4 w-full sm:w-max bg-green-300 text-green-700 font-semibold text-xs sm:text-base rounded-lg px-2'>
                    <img src={linkIcon} alt='ic' className={'h-4/5 aspect-square mr-2 my-auto'}></img>
                    <p className={'align-middle my-auto text-start w-full sm:w-max break-words sm:whitespace-nowrap'}>{`${FRONTEND_URL}/badge/${assertionData._id}`}</p>
                  </div>
                <ButtonGeneralImage text={'Copy Link'} onClick={copyToClipboardClickHandler} imagePosition={'left'} image={copyIcon} className={' bg-app-red text-white font-semibold mx-auto'}/>
              </div>
              {/* <div className='border bg-gray-100  flex flex-col sm:flex-row p-2 space-y-1 sm:space-y-0  mx-auto'>
              <div className='flex  w-max space-x-2'>
                  <a href={link} target='__blank' className='h-8' ><img src='/linkedinAddToProfile.png' alt='add_to_profile' /></a>
                  <a target='__blank' className='h-8' href={tweetLink}><img src={TwitterLogo} alt='tw' className='h-full'></img></a>
                    <a target='__blank' className='h-8' href={`https://www.facebook.com/sharer/sharer.php?u=${viewBadgeLink}&quote=Badge%20LAB!`}><img src={FacebookLogo} alt='fb' className='h-full'></img></a>
                  </div>
                <button onClick={copyToClipboardClickHandler} className='border rounded-lg px-2 mx-2 text-white bg-blue-500 hover:bg-blue-300'>Copy Link</button>
                <button onClick={downloadBadgeImage} className='border rounded-lg px-2 mx-2 text-white bg-blue-500 hover:bg-blue-300'>Download</button>
              </div> */}
              {assertionData && <ShareButtons viewBadgeLink={viewBadgeLink} tweetLink={tweetLink} facebookLink={facebookLink} linkedinLink={linkedinLink} />}
            </div>
          </div>
            )
          : <div className='mt-[100px] mx-auto'>Record not found </div>}
    </div>
  )
}

export default ViewBadge

function ShareButtons ({ viewBadgeLink, tweetLink, facebookLink, linkedinLink }) {
  return <div className='mx-auto my-2 flex space-x-2'>
    <a href={facebookLink} target='__blank' ><IconCircular image={facebookLogo} /></a>
    <a href={tweetLink} target='__blank' ><IconCircular image={twitterLogo} /></a>
    <a href={linkedinLink} target='__blank' ><IconCircular image={linkedinLogo} /></a>
    {/* <a href={viewBadgeLink} target='__blank' ><IconCircular image={shareLogo} /></a> */}
  </div>
}
