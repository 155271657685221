import React from 'react'

export default function ButtonGeneralImageTransparent ({ text, onClick, className, disabled, image, imagePosition }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
            // eslint-disable-next-line no-undef
      className={` ${disabled ? 'cursor-not-allowed ' : '  '} w-max px-2 h-8 sm:h-12 block rounded-lg text-app-red ${className} `} >
      <div className='flex flex-row px-0 sm:px-4 w-max'>
        <img src={image} alt='ic' className={`${imagePosition === 'left' ? 'order-1 mr-2' : 'order-2 ml-2'} h-4/5 aspect-square mr-2 my-auto`}></img>
        <div className={`${imagePosition === 'left' ? 'order-2' : 'order-1'}`}>{text}</div>
      </div>
      </button>
  )
}
