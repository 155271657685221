import React from 'react'
import EditAndDeleteButtons from '../EditAndDeleteButtons'

/** @description Creates a card that shows Group data. Used in admin. */
export default function GroupCard ({ id, name, participants, createdOn, modifiedOn, issuer, onEdit, onDelete }) {
  return <div className='flex flex-col relative bg-blue-100 border rounded-lg w-[300px] p-2 my-2 text-start'>
      <div className='flex text-2xl'>{name}</div>
      <hr className=' border-white my-1'></hr>
      <div className='flex flex-col text-sm'>
        <div className='flex'>Total participants : {participants && participants.length}</div>
        <div className='flex'>Created on : {new Date(createdOn).toLocaleString() }</div>
      <div className='flex'>Modified on : {new Date(modifiedOn).toLocaleString()}</div>
      {issuer && <div className='flex'>Issuer : {issuer}</div>}
    </div>
    {/* <hr className=' border-white my-1'></hr> */}
    <EditAndDeleteButtons id={id} onDelete={onDelete} onEdit={onEdit} />
      {/* <div className='flex flex-row mx-auto mt-2'>
      <button onClick={() => onEdit({ id })} className='border rounded-lg bg-green-400 w-20 mx-2'>Edit</button>
      <button onClick={() => onDelete({ id })} className='border rounded-lg bg-red-400 w-20 mx-2'>Delete</button>
      </div> */}
    </div>
}
