import React, { useEffect, useState } from 'react'
import Textinput from '../../_atomic-design/molecules/InputMock/TextInputGray'
import ListSelector from '../../_atomic-design/molecules/InputMock/ListSelector'
import ButtonCTA from '../../_atomic-design/atoms/ButtonMock/ButtonCTA'
import { makeAuthenticatedServerGETRequest, makeAuthenticatedServerRequest, makeServerGETRequest } from '../../utils/helpers'
import CrossRedSvg from '../../assets/images/Icons/CrossRedCircular.svg'
import CustomHr from '../../_atomic-design/molecules/Others/CustomHr'
import UploadCsv from '../../containers/Common/UploadCsv'
import FilterBar from '../../containers/General/FilterBar'
import GroupCard from '../../components/cards/GroupCard'
import DefaultDropdown from '../../_atomic-design/molecules/InputMock/DefaultDropdown'

/** Page to create or edit a group */
export default function CreateGroup () {
  const [groupName, setGroupName] = useState('') // name of the group
  const [list, setList] = useState([]) // list of all recipients
  const [selectedList, setSelectedList] = useState([])
  const [uploadEmailList, setUploadEmailList] = useState([])
  const [groupList, setGroupList] = useState([])
  const [search, setSearch] = useState('')
  const [groupId, setGroupId] = useState(null)
  const [mode, setMode] = useState('new') // mode = 'new' & 'modify'
  const [badgeClass, setBadgeClass] = useState('')
  const [badgeClassList, setBadgeClassList] = useState([])

  /** Format options to pass to dropdown component */
  const badgeClassOptions = badgeClassList.map((item) => ({ value: item._id, text: item.name }))

  useEffect(() => {
    fetchRecipients(setList)
  }, [uploadEmailList])

  /** Function to fetch all groups and badgeClasses from database */
  useEffect(() => {
    makeAuthenticatedServerGETRequest('/group/getGroups', {})
      .then((res) => {
        if (res.status === 200) {
          setGroupList(res.data.groups)
        }
      })
    makeServerGETRequest('/BadgeClass/getBadgeClasses', {})
      .then((res) => {
        if (res.status === 200) {
          setBadgeClassList(res.data.badgeClasses)
        } else {
          console.log('')
        }
      })
  }, [])

  // implements search.
  const filteredList = list.filter(item => selectedList.includes(item._id))

  useEffect(() => {
    const validUploadedEmails = list.filter(item => uploadEmailList.includes(item.email)).map(item => item._id)
    setSelectedList((prev) => [...prev, ...validUploadedEmails])
  }, [uploadEmailList])

  /** function to fetch all recipients from database */
  function fetchRecipients (onReciept) {
    makeAuthenticatedServerGETRequest('/recipient/getRecipients', {})
      .then((res) => {
        if (res.status === 200) {
          onReciept(res.data.recipients)
        }
      })
  }

  function onUploadSuccess (data) {
    const uploadedEmails = data.uploadedNameAndEmail.map(item => item.email)
    setUploadEmailList(uploadedEmails)
  }

  function selectHandler (data) {
    setSelectedList((prev) => { return [...prev, data] })
  }

  function unSelecthandler (data) {
    const tempArray = [...selectedList]
    const index = selectedList.indexOf(data)
    if (index > -1) { // only splice array when item is found
      tempArray.splice(index, 1) // 2nd parameter means remove one item only
    }
    setSelectedList(tempArray)
  }

  function clearData () {
    // setGroupName('')
    // setSelectedList('')
    // setUploadEmailList('')
    window.location.reload() // refresh page
  }

  /** This function is triggered when user presses submit button.
   * Makes API call to server depending on the mode. */
  function submitButtonHandler () {
    mode === 'new'
      ? makeAuthenticatedServerRequest('/group/createGroup', 'POST', { groupName, selectedList, badgeClass })
        .then((res) => {
          if (res.status === 200) {
            alert('saved')
            clearData()
          } else {
            alert(res.data.error.message)
          }
        })
      : makeAuthenticatedServerRequest('/group/editGroup', 'put', { groupId, groupName, selectedList, badgeClass })
        .then((res) => {
          if (res.status === 200) {
            alert('saved')
            clearData()
          } else {
            alert(res.data.error.message)
          }
        })
  }

  /** Sets mode to edit. Triggered when user presses edit button. Fetches details of the selected Group. */
  function editButtonhandler ({ id }) {
    console.log('saving modified group id:', id)
    setGroupId(id)
    setMode('modify')
    makeAuthenticatedServerGETRequest('/group/getGroupDetailsByGroupId', { groupId: id })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data)
          const participantsId = res.data.group.participants.map(item => item._id)
          setSelectedList(participantsId)
          setGroupName(res.data.group.name)
          setBadgeClass(res.data.group.badgeClass._id)
        }
      })
  }

  /** Function to delete the selected Group */
  function deleteButtonHandler ({ id }) {
    const consent = window.confirm('Are you sure you want to delete this group? This will permanently delete this group.')
    if (!consent) {
      return
    }
    makeAuthenticatedServerRequest('/group/deleteGroup', 'delete', { id })
      .then(res => {
        if (res.status === 200) {
          alert('Deleted successfully')
          window.location.reload()
        } else {
          alert('Some error occured')
        }
      })
  }

  function badgeClassChangeHandler (e) {
    setBadgeClass(e.target.value)
  }

  return <div className='mx-auto w-full h-screen flex flex-col sm:flex-row bg-gray-200 relative pt-[100px] sm:pt-[80px]'>
    <div className=' p-10 w-max mx-auto h-max bg-white rounded-lg text-start space-y-5'>
      <div className='text-xl font-bold '>Groups</div>
      <FilterBar search={search} setSearch={setSearch} />
      <div>
        {groupList.map((group) => {
          if (group.name.toLowerCase().includes(search.toLocaleLowerCase())) {
            return <GroupCard key={group._id} id={group._id} name={group.name} participants={group.participants} createdOn={group.createdAt} modifiedOn={group.updatedAt} issuer={group.badgeClass.issuer.name} onEdit={editButtonhandler} onDelete={deleteButtonHandler} />
          }
        })}
      </div>
    </div>
    <div className=' p-10 w-max mx-auto h-max bg-white rounded-lg text-start space-y-5'>
      <div className='text-xl font-bold '>{mode === 'new' ? 'Create New Group' : 'Modifying'}</div>
      <Textinput label='Group Name *' value={groupName} onChange={(e) => { setGroupName(e.target.value) }} placeholder='Group Name' />
      <DefaultDropdown label='Badge' onChange={badgeClassChangeHandler} options={badgeClassOptions} defaultSelected={badgeClass} />
      <ListSelector headingText={'Recipients'} list={list} selectedList={selectedList} selectHandler={selectHandler} unSelecthandler={unSelecthandler} />
      <CustomHr text={'OR'} />
      <div>Upload Excel Sheet</div>
      <div className='mx-auto w-max'>
        <UploadCsv onSuccess={onUploadSuccess} />
      </div>
    </div>

    <div className=' p-10 w-max h-max mx-auto bg-white rounded-lg text-start'>
      <p>Preview</p>
      {filteredList.map((item, index) => {
        return <div key={item + index} className='pl-4 pr-1 py-1 my-1 border rounded-lg flex justify-between min-w-max w-[220px] sm:w-[392px]'>
          <div className='flex flex-col'>
            <p>{item.name} </p>
            <hr></hr>
            <p>{item.email}</p>
          </div>
          <div ><button onClick={() => unSelecthandler(item._id)}><img src={CrossRedSvg} alt='x'/></button></div>
      </div>
      })}
      <ButtonCTA text={ mode === 'new' ? 'Create New Group' : 'Modify Group'} onClick={submitButtonHandler} disabled={selectedList.length === 0}/>
      </div>
  </div>
}
