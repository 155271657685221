import React from 'react'
import './App.css'
import AppRoutes from './Routes/AppRoute'

function App () {
  return (
    <div className='App'>
      <AppRoutes />
    </div>
  )
}

export default App
