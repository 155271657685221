import React from 'react'
import CountUp from 'react-countup'

/**
 * @description Shows a box with a heading and a number.
 * The number is counted up from 0 to value (with counting animations -- used 'react-countup') upon coming to screen.
 * Useful for showing some statistics to user. */
export default function InfoCard ({ heading, value }) {
  return <div className='flex flex-col content-between bg-teal-300 m-1 p-5 sm:w-[250px] w-[200px] h-max text-white rounded-lg shadow-lg text-start'>
    <div className='flex font-bold sm:text-2xl text-lg'>{heading}</div>
    <hr></hr>
    <div className='flex font-bold sm:text-xl text-md'><CountUp start={0} end={value} duration={5}/></div>
  </div>
}
