import React from 'react'
import AuthComponent from './AuthComponent'
import Navbar from '../../components/Navbar'

/** This page opens when user clicks on login button on Homepage.
 * Contains login for login, account creation, forgot password, etc.
*/
export default function AuthRoute () {
  const bgStyle = {
    // backgroundImage: `url(${MainBackgroundSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right'
  }
  return (
    <div className='w-full bg-gray-300 min-h-screen h-full relative flex flex-col justify-center items-center pt-16 sm:pt-20' style={bgStyle}>
      <Navbar />
      <AuthComponent className='z-20 mx-auto relative' />
    </div>
  )
}
