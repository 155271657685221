import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import ReactLoading from 'react-loading'
import GeneralText from '../../_atomic-design/atoms/Text/GeneralText'
import { useDispatch } from 'react-redux'
import { authActions } from '../../store/authSlice/authSlice'

/** This page opens after user is authenticated by third party oAuth2 clients such as facebook, linkedin, google, etc */
export default function Redirecting () {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    const getdata = async () => {
      if (token && token.length > 0) {
        try {
          const res = await makeAuthenticatedServerRequest(
            `/auth/verifyEmail/?token=${token}`, 'POST', {}
          )
          if (res.status === 200) {
            dispatch(authActions.USER_EMAIL_VERIFICATION_SUCCESS())
            navigate('/')
          }
        } catch (error) {
          alert('Please verify again')
          navigate('/SendVerificationMail')
        }
      }
    }
    getdata()
  }, [token])
  return (
    <div className='bg-[#F3F0FA] w-full h-full'>
      <div className='flex justify-center items-center h-full w-full'>
        <div className='flex flex-col items-center justify-center'>
          <ReactLoading type='spin' color='#916AF8' height={40} width={35} />
          <GeneralText text='Loading' className='text-lg ' textColor='#8562D5' />
        </div>
      </div>
    </div>
  )
}
