import React from 'react'
import EditAndDeleteButtons from '../EditAndDeleteButtons'

/** @description Creates an Issuer Card to display Issuer details. Used in admin. */
export default function IssuerCard ({ id, name, email, url, description, image, onEdit, onDelete }) {
  // default generic image used in case if Issuer logo image is not supplied to this card while using.
  const imageUrl = image || 'https://images.pexels.com/photos/2846814/pexels-photo-2846814.jpeg?auto=compress&cs=tinysrgb&w=1600'
  return (
    <div className="flex flex-col relative mx-auto w-[90vw] sm:w-[500px] h-[200px] bg-white rounded-xl shadow-xl ">
      <div className="h-1/3 bg-blue-100 text-4xl p-2 sm:p-10 align-bottom rounded-t-xl relative ">
        <div className='flex absolute bottom-2'>{name}</div>
      </div>
      {/* <button onClick={() => onClick({ id })} className='w-20 bg-blue-100 rounded-lg absolute top-2 right-2'>edit</button> */}
      <hr></hr>
      <img className='absolute right-10 top-10 h-20 w-20 rounded-full border bg-white ' src={imageUrl} alt='logo' />
      <div className="h-2/3 bg-green-200 rounded-b-xl text-md text-start p-2 sm:p-10 sm:pt-0">
        <div className='hover:text-xl '>{email}</div>
        <div className='flex'>
          <div>Website: </div>
          <div className='hover:text-xl '>
            <a href={url} target='__blank'>{url}</a>
          </div>
        </div>
        <div className='flex'>
        <div>Description: </div>
        <div className='overflow-auto hover:overflow-visible hover:h-max hover:bg-white rounded-md  w-full '>{description}</div>
        </div>
      </div>
      <EditAndDeleteButtons id={id} onEdit={onEdit} onDelete={onDelete} />
    </div>
  )
}
