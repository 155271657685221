import React, { useEffect, useState } from 'react'
import { makeAuthenticatedServerGETRequest, makeAuthenticatedServerRequest, makeServerGETRequest } from '../../utils/helpers'
import Textinput from '../../_atomic-design/molecules/InputMock/TextInputGray'
import UploadCsv from '../../containers/Common/UploadCsv'
import CustomHr from '../../_atomic-design/molecules/Others/CustomHr'
import RecipientCard from '../../components/cards/RecipientCard'
import FilterBar from '../../containers/General/FilterBar'

/** Page to add or edit a Recipient. */
function AddRecipient () {
  const [name, setName] = useState('') // name of the recipient
  const [email, setEmail] = useState('') // email of the recipient
  const [description, setDescription] = useState('') // description of the recipient
  const [image, setImage] = useState('') // profile picture of the recipient
  const [recipientList, setRecipientList] = useState([]) // Stores list of all the recipients fron the database
  const [id, setId] = useState(null) // Stores id of recipient when in editing mode
  const [mode, setMode] = useState('new') // mode = 'new' & 'modify'
  const [search, setSearch] = useState('') // Used for searching recipient from list of recipients

  /** This function fetches all the recipients from database. */
  useEffect(() => {
    makeAuthenticatedServerGETRequest('/recipient/getRecipients', {})
      .then((res) => {
        if (res.status === 200) {
          setRecipientList(res.data.recipients)
        }
      })
  }, [])

  /** This function clears all state variables by refreshing the page. */
  function clearData () {
    // setName('')
    // setEmail('')
    // setDescription('')
    // setImage('')
    window.location.reload() // refreshes page
  }

  /** This function is triggered when user clicks on Submit buton.
   * Depending on the mode, sends request to either create a new recipient or update an existing Recipient. */
  function submitButtonHandler (e) {
    mode === 'new'
      ? makeAuthenticatedServerRequest('/recipient/createRecipient', 'POST', { name, email, description, image })
        .then((res) => {
          if (res.status === 200) {
            alert('saved')
          } else {
            console.log(res.data)
            alert(res.data.error.message)
          }
          clearData()
        })
      : makeAuthenticatedServerRequest('/recipient/updateRecipient', 'put', { id, name, email, description, image })
        .then((res) => {
          if (res.status === 200) {
            alert('saved')
          } else {
            console.log(res.data)
            alert(res.data.error.message)
          }
          clearData()
        })
  }

  /** This function changes the mode to Editing mode and fetches details of selected recipient */
  function editButtonhandler ({ id }) {
    window.scrollTo(0, 0) // move to top of page
    setMode('modify')
    setId(id)
    makeServerGETRequest('/recipient/getRecipientById', { recipientId: id }).then((res) => {
      if (res.status === 200) {
        setName(res.data.recipient.name)
        setEmail(res.data.recipient.email)
        setImage(res.data.recipient.image)
        setDescription(res.data.recipient.description)
      }
    })
  }

  /** Function to delete an existing Recipient. */
  function deleteButtonHandler ({ id }) {
    const consent = window.confirm('Are you sure you want to delete the user? It is highly recommended NOT to delete a recipient. Deleting may lead to website crash !!')
    if (!consent) {
      return
    }
    makeAuthenticatedServerRequest('/recipient/deleteRecipient', 'delete', { recipientId: id })
      .then((res) => {
        if (res.status === 200) {
          alert('Deleted successfully')
        } else {
          alert('some error occured')
        }
        window.location.reload()
      })
  }

  return (
    <div className='flex flex-col sm:flex-row justify-around  mx-auto w-full h-full sm:h-screen bg-gray-200 relative pt-[200px] sm:pt-[80px]'>
      <div className='flex flex-col order-2 sm:order-1'>
        <FilterBar search={search} setSearch={setSearch} />
        <div className='flex flex-col order-2 sm:order-1 h-full sm:h-[90vh] mx-auto sm:mx-0 mt-2 overflow-scroll space-y-5 sm:p-10 p-2 bg-white rounded-xl'>
          {recipientList.map(recipient => {
            if (recipient.name.toLowerCase().includes(search.toLocaleLowerCase())) {
              return <RecipientCard key={recipient._id} id={recipient._id} name={recipient.name} email={recipient.email} image={recipient.image} onEdit={editButtonhandler} onDelete={deleteButtonHandler} />
            }
          }
          )}
        </div>
      </div>
      <div className=' flex flex-col order-1 sm:order-2 p-10 w-max mx-auto sm:mx-0 bg-white rounded-lg text-start space-y-5'>
        <Textinput label='Full Name *' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Full Name' />

        <Textinput label='Email *' value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='Email' disabled={mode === 'modify'} />

        {/* <Textinput label='Description' value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder='Description' />

        <Textinput label='Profile Picture Url' value={image} onChange={(e) => { setImage(e.target.value) }} placeholder='Profile Picture' /> */}

        <button className='p-1 px-2 border rounded-lg bg-cyan-200' onClick={submitButtonHandler}>{mode === 'new' ? 'Create Recipient' : 'Modify'}</button>
        <CustomHr text={'OR'} />
        <div className='flex flex-col mx-auto w-max'>
          <p>Upload Excel Sheet</p>
          <div className='flex w-max mx-auto '>
          <UploadCsv />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddRecipient
