import React from 'react'

/**
 * @description Creates a button matching the parimary theme of the website.
 * Background is filled with primary color, text is white. If the button is
 * disabled as per 'disabled' prop, background color of Grey is shown.
 * App theme colors are defined in tailwind.congif.js file.
 */
export default function ButtonPrimary ({ className, onClick, text, size, disabled }) {
  const buttonSize = size === undefined ? ' h-12 ' : size

  return (
    <button
      onClick={onClick}
      className={` ${className} ${buttonSize}  bg-[#1479ff] px-10  rounded-md text-white ${disabled ? 'bg-app-grey cursor-not-allowed' : 'bg-app-primary cursor-pointer'}`}
      disabled={disabled}
    >
      {text}
    </button>
  )
}
