import React from 'react'

/**
 * @description Shows Edit and Delete buttons. Can be added to any card that needs this functionality.
 * @param id - ID of object for which edit and function needs to be called.
 * @param onEdit - This function is called with id parameter when user presses EDIT button.
 * @param onDelete - This function is called with id parameter when user presses DELETE button.
 */
export default function EditAndDeleteButtons ({ id, onEdit, onDelete }) {
  return <div className='absolute flex space-x-3 p-1 rounded-lg top-1 right-1  bg-white'>
  <button onClick={() => onEdit({ id })} className='rounded-full'><img width={'30px'} src='https://www.svgrepo.com/show/73131/edit-button.svg' alt='edit' /></button>
  {/* <button onClick={() => onDelete({ id })} className='rounded-full'><img width={'30px'} src='https://www.svgrepo.com/show/21045/delete-button.svg' alt='del' /></button> */}
</div>
}
