import React from 'react'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import ProfileAddIcon from '../../assets/images/Icons/ProfileAdd.svg'
import HiddenInput from '../../_atomic-design/atoms/Home/HiddenInput'
import { fileUploadRequest } from '../../utils/helpers'

/**
 * @description Creates a container that shows an upload area. User can upload their profile
 * picture image. The uploaded image is sent to the backend server for saving in database.
 * After successful response from server, the image is set as profile picture of loggedin user.
 */
export default React.memo(function AddProfilePicture ({ profilePicture, setprofilePicture }) {
  /**
   * @description creates new formData with received image file. Forwards this image file to be sent to backend server
   * for uploading to s3. After succesfull upload, sets the received image url as profile picture url.
    */
  const uploadFiles = async (file) => {
    const formData = new FormData()
    formData.append('image', file)
    // make an API request to upload the user uploaded image file to backend server. image attached as form data.
    const res = await fileUploadRequest('/upload/profile-photo', formData)
    // after successful upload, set the image as profile picture
    setprofilePicture(res.data.url)
  }

  /** This function gets triggered when user drops a file on image area. Calls uploadFiles function with uploaded file. */
  const onFileDrop = async (e) => {
    uploadFiles(e.target.files[0])
  }

  return (
    <div className='relative'>
      <div className='relative bg-app-blue-2xlight rounded-lg overflow-hidden w-28 h-28 flex justify-center items-center mb-4 mt-2 hover:opacity-80 transition-opacity duration-400'>
        <ImageTag
          src={profilePicture || ProfileAddIcon}
          className={profilePicture ? 'w-full object-cover aspect-square ' : 'p-4 border-2 border-red-400  rounded-lg'}
        />
        <HiddenInput
          onChange={onFileDrop}
          acceptFileType='image/png, image/jpeg'
        />
      </div>
    </div>
  )
})
