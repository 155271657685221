import axios from 'axios'
import { getCookies, setAuthTokens } from './cookieStorage'
import Cookies from 'js-cookie'

/** Backend server URL */
const BASE_URL = process.env.REACT_APP_API_BASE_URL

/**
 * @description Makes general (UN-AUTHENTICATED) API request to the backend server
 * @param route - path to which API call to be made
 * @param method - method of request | possible value - 'GET', 'POST', 'DELETE', 'PUT', etc
 * @param body - payload to be sent with API call { key1: value1, key2: value2 }
 * @returns response received from the backend server
 * */
export const makeServerRequest = async (route, method, body) => {
  try {
    const response = await axios({
      method,
      url: `${BASE_URL}${route}`,
      headers: { 'content-type': 'application/JSON' },
      data: body
    })
    return response
  } catch (error) {
    return error.response
  }
}

/**
 * @description Makes UN-AUTHENTICATED GET request to the backend server
 * @param route - path to which API call to be made
 * @param params - payload to be sent with API call as query parameters { key1: value1, key2: value2 }
 * @returns response received from the backend server
 * */
export const makeServerGETRequest = async (route, params) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BASE_URL}${route}`,
      headers: { 'content-type': 'application/JSON' },
      params
    })
    return response
  } catch (error) {
    return error.response
  }
}

/**
 * @description Makes general (AUTHENTICATED) API request to the backend server
 * For authentication accessToken is sent as Bearer Token along with the request
 * @param route - path to which API call to be made
 * @param method - method of request | possible value - 'GET', 'POST', 'DELETE', 'PUT', etc
 * @param body - payload to be sent with API call { key1: value1, key2: value2 }
 * @returns response received from the backend server
 * */
export const makeAuthenticatedServerRequest = async (route, method, body) => {
  /** The function `refreshAuthToken` checks whether is user is logged in before making the API call.
   * If user is not logged in then prompt the user to login first. */
  refreshAuthToken()
  const { accessToken } = getCookies()
  try {
    const response = await axios({
      method,
      url: `${BASE_URL}${route}`,
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/JSON' },
      data: body
    })
    return response
  } catch (error) {
    return error.response
  }
}

/**
 * @description Makes AUTHENTICATED GET request to the backend server
 * For authentication accessToken is sent as Bearer Token along with the request
 * @param route - path to which API call to be made
 * @param params - payload to be sent with API call as query parameters { key1: value1, key2: value2 }
 * @returns response received from the backend server
 * */
export const makeAuthenticatedServerGETRequest = async (route, params) => {
  /** The function `refreshAuthToken` checks whether is user is logged in before making the API call.
   * If user is not logged in then prompt the user to login first. */
  refreshAuthToken()
  const { accessToken } = getCookies() // read accessToken from cookies

  try {
    const response = await axios({
      method: 'GET',
      url: `${BASE_URL}${route}`,
      headers: { Authorization: `Bearer ${accessToken}` },
      params
    })

    return response
  } catch (error) {
    return error.response
  }
}

/**
 * @description makes request the refresh the refreshToken. Due to the inherent nature of refreshToken,
 * they expire after a certian fixed time interval. So, fresh refreshTokens need to be requested from the
 * server in regular intervals.
 * This function reads refreshToken stored in cookies. If valid tokens found then requests fresh refreshTokens
 * from the server and stores them in the cookies. If no valid tokens are found in the server, destroys the
 * userDetails stored in the cookies, effectively LOGGING OUT the user,thereby requesting the user to login again.
 * @param none
 * @returns none
 */
export const refreshAuthToken = async () => {
  const { refreshToken } = getCookies() // read refreshToken from cookies
  const res = await makeServerRequest('/auth/refreshToken', 'POST', {
    refreshToken
  })
  if (res.status === 200) {
    setAuthTokens({ accessToken: res.data.accessToken, refreshToken: res.data.refreshToken })
  } else {
    Cookies.remove('userDetails')
  }
}

/**
* @description This function is for uploading file to the server unauthenticated
* @param {String} route - The route to be called
* @param {String} filePath - The file path to be passed
* @return {Object} - The response received from the server
*/
export const fileUploadRequest = (route, filePath, uploadProgressEmitter) => {
  const response = axios({
    method: 'POST',
    url: `${BASE_URL}${route}`,
    data: filePath,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: uploadProgressEmitter
  })

  return response
}
