import amarnathTestimonial from '../images/amarnathTestimonial.svg'
import stutiTestimonial from '../images/stutiTestimonial.svg'
import debarkaTestimonial from '../images/debarka_1c.png'

export const testimonials = [
  {
    img: amarnathTestimonial,
    mesage: 'Digital badges have become an important part of my professional development. They provide a way to showcase my expertise and accomplishments to colleagues and potential employers.',
    degignation: 'Mr. Amarnath Kumar, Full Stack Engineer, New Delhi',
    name: 'Amarnath Kumar',
    bgcolor: '#EBFAF6',
    id: 1
  },
  {
    img: stutiTestimonial,
    mesage: 'Digital badges have become an important part of my professional development. They provide a way to showcase my expertise and accomplishments to colleagues and potential employers.',
    degignation: 'Stuti Mishra, ML Engineer, New Delhi',
    name: 'Stuti Mishra',
    bgcolor: '#EBFAF6',
    id: 2
  },
  {
    img: debarkaTestimonial,
    mesage: 'I earned a digital badge from d.kraft and was able to showcase my skills and experience to potential employers. Within a month, I had landed a new job with a higher salary!',
    degignation: 'Prof. Debarka Sengupta, IIITD, New Delhi',
    name: 'Debarka Sengupta',
    bgcolor: '#E8F2FF',
    id: 3
  }
]
