import React, { useState } from 'react'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import ProfileAddIcon from '../../assets/images/Icons/ProfileAdd.svg'
import HiddenInput from '../../_atomic-design/atoms/Home/HiddenInput'
import { fileUploadRequest } from '../../utils/helpers'
import ReactLoading from 'react-loading'

/**
 * @description This container shows a file upload area where files containing {name, email}
 * of receipients can be uploaded. The uploaded file is sent to the backend for extracting
 * and processing the data. This container differs from 'UploadEmailList' which processes the
 * uploaded file in frontend itself.
 */
export default React.memo(function UploadCsv ({ onSuccess }) {
  // Controls loading animation. If a file is dropped on upload area, it shows loading animation until
  // file has been processed and response is received from the backend server.
  const [uploading, setUploading] = useState(false)

  /**
   * @description Handles uploading file to the backend server and calling the callback 'onSuccess'
   * with received data from server.
   */
  const uploadFiles = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    setUploading(true) // start showing loading animation
    // make api call to upload and process the uploaded file. API returns extracted recipients {name, email} from the file.
    const res = await fileUploadRequest('/upload/recipientList', formData)
    setUploading(false) // stop showing loading animation
    if (res.status === 200) {
      alert('upload successful')
      // if 'onSuccess' function is provided while using this 'UploadCsv' container then only call 'onSuccess' function
      if (onSuccess) {
        onSuccess(res.data.dataFromJson) // call 'onSuccess' function with data received from backend server
      }
    }
  }
  /** Triggered whenever user drops/uploads a file in the input area. Calls 'uploadFiles' with the uploaded file. */
  const onFileDrop = async (e) => {
    uploadFiles(e.target.files[0])
  }

  return (
    <div className='relative'>
      {uploading
        ? <ReactLoading type='spin' color='#916AF8' height={112} width={112} />
        : <div className='relative bg-app-blue-2xlight rounded-lg overflow-hidden w-28 h-28 flex justify-center items-center mb-4 mt-2 hover:opacity-80 transition-opacity duration-400'>
        <ImageTag
          src={ProfileAddIcon}
          className={'w-full object-cover aspect-square p-4 border-2 border-red-400  rounded-lg'}
        />
        <HiddenInput
          onChange={onFileDrop}
          acceptFileType='.xls,.xlsx'
        />
      </div>}
    </div>
  )
})
