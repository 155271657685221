import React from 'react'
import EditAndDeleteButtons from '../EditAndDeleteButtons'

/** @description Creates a Recipient card to show recipient details. Used in admin. */
export default function RecipientCard ({ id, name, email, image, onEdit, onDelete }) {
  // A generic user logo is used if user profile image is not supplied while using this card.
  const imageUrl = image || 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/1200px-User_icon_2.svg.png'
  return (
    <div className="flex flex-col relative mx-auto w-[400px] h-[100px] bg-white rounded-xl shadow-xl ">
      <div className=" bg-blue-100 text-2xl pl-10 h-[50px]  align-bottom rounded-t-xl relative ">
        <div className='flex absolute bottom-0'>{name}</div>
      </div>
      <hr></hr>
      {/* <button onClick={() => onClick({ id })} className='w-20 bg-blue-100 rounded-lg absolute top-[40px] right-2'>edit</button>
      <button onClick={() => onDelete({ id })} className='w-6 bg-red-400 rounded-full absolute -top-2 -right-2'>X</button> */}
      <img className='absolute -left-5 top-[30px] h-10 w-10 rounded-full border bg-white ' src={imageUrl} alt='logo' />
      <div className="h-[50px] bg-green-200 rounded-b-xl text-md text-start pl-10">
        <div className='hover:text-xl '>{email}</div>
      </div>
      <EditAndDeleteButtons id={id} onEdit={onEdit} onDelete={onDelete} />
    </div>
  )
}
