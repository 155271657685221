import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { authActions } from '../store/authSlice/authSlice'
import PopUp from '../containers/General/PopUp'
import popUp from '../assets/images/Icons/popUp.svg'
// import { toast } from 'react-toastify'

/** This page displays Logout page. Logout happens by expiring the stored userDetails cookies.
 * Also logout event is dispatched to logout the user from redux store.
 */
function Logout (props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function logoutButtonHandler (e) {
    e.preventDefault()

    dispatch(authActions.logoutSuccess())
    // seting expiry date to now , it will make cookies void
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
    })
    alert('You have been successfully logged out')

    /** Navigate the user to homepage after successfull logout */
    navigate('/')
  }

  return (
    <div className='w-screen h-screen bg-gray-300 flex p-10'>
      <PopUp imgSrc={popUp} textMain=' Logout ??' onProceed={logoutButtonHandler} onCancel={() => { navigate('/') }} />
    </div>
  )
}

export default Logout
