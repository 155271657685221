import React from 'react'
import ListSelector from '../_atomic-design/molecules/InputMock/ListSelector'

export default function ViewGroupDetails ({ groupDetails }) {
  const selectedList = []
  if (groupDetails.badgeAwardStatus) {
    for (const [key, value] of Object.entries(groupDetails.badgeAwardStatus)) {
      if (value) {
        selectedList.push(key)
      }
    }
  }
  return <div>
    <div>{groupDetails.name}</div>
    <div>Total Recipients : {groupDetails.participants.length}</div>
    <ListSelector headingText={'Recipients'} list={groupDetails.participants} selectedList={selectedList} selectHandler={() => {}} unSelecthandler={() => {}} />
  </div>
}
