import React from 'react'
import ImageTag from './ImageTag'

export default function IconCircular ({ image }) {
  return <ImageTag
    className={'aspect-square object-cover rounded-full hover:opacity-70 opacity-100 '}
    src={image}
    alt='icon'
  />
}
