import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import logoIcon from '../assets/images/Icons/LogoIcon4.png'
import cross from '../assets/images/Icons/cross.svg'
import NavIcon from '../assets/images/Icons/NavIconPhone.svg'

/** See Navbar function for details about this function */
export default function NavbarAdmin () {
  const isAdminLoggedIn = useSelector((state) => state.auth.isAdminLoggedIn)
  const [isNavBarOpen, setIsNavBarOpen] = useState(false)

  function changeNavBarVisibility () {
    setIsNavBarOpen((prev) => { return !prev })
  }

  return (
    <div className={(isNavBarOpen ? ' h-max ' : ' h-[55px] ') + ' w-screen px-2 bg-white fixed top-0 flex flex-col sm:flex-row  z-40 justify-between overflow-hidden'} >
      <div className='flex sm:mx-10 my-auto justify-between '>
        <Link to='/admin/Dashboard' className='my-auto '><img src={logoIcon} alt='logo' className='w-32' /></Link>
        <div className='my-auto sm:hidden' onClick={changeNavBarVisibility}>
          {
          isNavBarOpen
            ? <img src={cross} alt='cross' className='w-10 h-10' />
            : <img src={NavIcon} alt='navicon' className='w-10 h-10' />
        }
        </div>
      </div>
      <div className='flex flex-col sm:flex-row my-auto'>
          {/* <Link to='/admin' className=' mx-2 my-1 text-white h-max p-2 border sm:border-none rounded-xl '>Home</Link> */}
        {isAdminLoggedIn
          ? (
            <div className='flex flex-col sm:flex-row '>
                <NavLink to='/admin/Dashboard' className={({ isActive }) => isActive ? ' text-black font-bold mx-2 my-1  h-max p-2 rounded-xl border sm:border-none ' : ' mx-2 my-1  h-max p-2 rounded-xl border sm:border-none  '}>Dashboard</NavLink>
                <NavLink to='/admin/AddIssuer' className={({ isActive }) => isActive ? ' text-black font-bold mx-2 my-1  h-max p-2 rounded-xl border sm:border-none ' : ' mx-2 my-1  h-max p-2 rounded-xl border sm:border-none  '}>Issuers</NavLink>
                <NavLink to='/admin/AddRecipient' className={({ isActive }) => isActive ? ' text-black font-bold mx-2 my-1  h-max p-2 rounded-xl border sm:border-none ' : ' mx-2 my-1  h-max p-2 rounded-xl border sm:border-none  '}>Recipients</NavLink>
                <NavLink to='/admin/createGroup' className={({ isActive }) => isActive ? ' text-black font-bold mx-2 my-1  h-max p-2 rounded-xl border sm:border-none ' : ' mx-2 my-1  h-max p-2 rounded-xl border sm:border-none  '}>Groups</NavLink>
                <NavLink to='/admin/AddBadgeClass' className={({ isActive }) => isActive ? ' text-black font-bold mx-2 my-1  h-max p-2 rounded-xl border sm:border-none ' : ' mx-2 my-1  h-max p-2 rounded-xl border sm:border-none  '}>Badges</NavLink>
                <NavLink to='/admin/AwardBadge' className={({ isActive }) => isActive ? ' text-black font-bold mx-2 my-1  h-max p-2 rounded-xl border sm:border-none ' : ' mx-2 my-1  h-max p-2 rounded-xl border sm:border-none  '}>Award Badge</NavLink>
                <NavLink to='/admin/AwardBadgeMultiple' className={({ isActive }) => isActive ? ' text-black font-bold mx-2 my-1  h-max p-2 rounded-xl border sm:border-none ' : ' mx-2 my-1  h-max p-2 rounded-xl border sm:border-none  '}>Award Badge ++</NavLink>
            </div>
            )
          : (<div></div>) }
      </div>
      <div className='mx-2 my-auto  h-max p-2 rounded-xl '>
        {isAdminLoggedIn
          ? (
            <Link to='/logout'>Log Out</Link>
            )
          : (
            <Link to='/admin/login'>Log In</Link>
            )}
      </div>
    </div>
  )
}
