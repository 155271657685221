
const questions = [
  {
    id: 1,
    questionText: 'How do I earn a digital badge?',
    answerText: 'To earn a digital badge, you will need to complete the requirements of the course outlined by d.kraft. This may include completing a course or program, passing an exam, or demonstrating a specific skill or competency.'
  },
  {
    id: 2,
    questionText: 'How are digital badges verified?',
    answerText: ' Digital badges are verified through a secure platform that ensures the badge was earned by the individual and that the information displayed on the badge is accurate. '
  },
  {
    id: 3,
    questionText: 'How can I display my digital badges?',
    answerText: 'Digital badges can be displayed on social media profiles, email signatures, resumes, and personal websites. The badges include a secure link that allows potential employers or peers to verify the badge\'s authenticity '
  }

]

export default questions
