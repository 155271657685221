import React from 'react'
import handPointer from '../../assets/images/Icons/handPointer.png'

/**
 * @description Creates a card that shows an image on left side (similar to bullet point marker),
 * a heading and an explanation or subtext below the heading. This card may be considered as a
 * custom bullet point.
 * @param feature - {headingText: 'a small heading', featureText: 'a description of the heading'}
 */
// eslint-disable-next-line react/prop-types
export function FeatureCard ({ feature }) {
  return (
    <div className='w-11/12 sm:w-4/5 rounded-xl my-10 p-5 flex flex-row mx-auto text-start bg-white backdrop-blur-lg'>
      <div className='w-[100px] min-w-[50px] mr-2 '><img src={handPointer} alt='pointer' className='' /></div>
      <div className='my-auto'>
        <div className='text-xl font-bold'>{feature.headingText}</div>
        <div className='flex text-lg'>{feature.featureText}</div>
      </div>
    </div>
  )
}
