import React from 'react'
import BgFeatures from '../../assets/images/BgFeatures.svg'
import features from '../../assets/data/featuresData'
import HeadingXlg from '../../_atomic-design/atoms/Text/HeadingXlg'
import { FeatureCard } from '../../components/cards/FeatureCard'

/** @description This container creates entire Features section displayed in homepage. */
export default function Features () {
  // defines background image and its properties
  const bgImage = {
    backgroundImage: `url(${BgFeatures})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <div id='features' className='min-h-screen h-full relative pt-24 md:pt-32 pb-24 mt-2 rounded-[20px] overflow-hidden' style={bgImage}>
      <HeadingXlg text='Benefits' className=' mb-[100px] sm:mb-0 ' />
      {features.map((item) => { return <FeatureCard key={item.id} feature={item} /> })}

    </div>
  )
}
