import React, { useState } from 'react'
import FilterBar from '../../../containers/General/FilterBar'

/**
 * @description Creates a component that shows a list of cards containing {_id: id, name: 'some name', email: 'somemail@mail.com'}
 * with checkboxes and a search bar. Search can be performed by typing the name.
 * @param list - [{_id: id, name: 'some name', email: 'somemail@mail.com'}] Array of objects
 * @param selectedList - [id1, id2, ...] Array of IDs
 * @param headingText - Heading to be displayed
 * @param selectHandler - function to be called when user 'checks' an item
 * @param unSelecthandler - function to be called when user 'un-checks' an item
 */
export default function ListSelector ({ list, selectedList, headingText, selectHandler, unSelecthandler }) {
  const [search, setSearch] = useState('')

  function clickHandler (e) {
    if (e.target.checked) {
      selectHandler(e.target.value)
    } else {
      unSelecthandler(e.target.value)
    }
  }

  return <div>
    <p className='text-lg font-bold'>{headingText}</p>
    <FilterBar search={search} setSearch={setSearch}/>
    <div className='h-max max-h-[300px] overflow-scroll'>
      {list.map((item, index) => {
        if (item.name.toLowerCase().includes(search.toLocaleLowerCase())) {
          return <div key={item.email + index} className='p-2 my-1 border rounded-lg flex'>
        <input type='checkbox' name='l1' value={item._id} onChange={clickHandler} checked={selectedList.includes(item._id)} />
        <label className='mx-4'>
          <div className='flex flex-col'>
            <p>{item.name} </p>
            <hr></hr>
            <p>{item.email}</p>
          </div>
        </label>
      </div>
        }
      })}
    </div>
  </div>
}
