import React from 'react'

export default function GeneralTextInput (props) {
  return (
    <>
        <input {...props} />
        <label htmlFor={props.id}></label>
    </>
  )
}
