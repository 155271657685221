import React, { useEffect, useState } from 'react'
import { makeAuthenticatedServerGETRequest, makeAuthenticatedServerRequest, makeServerGETRequest } from '../../utils/helpers'
import Textinput from '../../_atomic-design/molecules/InputMock/TextInputGray'
import DefaultDropdown from '../../_atomic-design/molecules/InputMock/DefaultDropdown'
import CrossRedSvg from '../../assets/images/Icons/CrossRedCircular.svg'
import ButtonCTA from '../../_atomic-design/atoms/ButtonMock/ButtonCTA'
import ListSelector from '../../_atomic-design/molecules/InputMock/ListSelector'
import BadgeCardSmall from '../../components/cards/BadgeCardSmall'

/** page to award multiple badges at once to participants of a group */
export default function AwardBadgeMultiple () {
  const [recipientList, setRecipientList] = useState([])
  const [selectedList, setSelectedList] = useState([])
  // const [badgeClassList, setBadgeClassList] = useState([])
  const [badgeClass, setBadgeClass] = useState('')
  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [evidence, setEvidence] = useState('')
  const [narrative, setNarrative] = useState('')
  const [groupList, setGroupList] = useState([])
  const [selectedGroupId, setSelectedGroupId] = useState(null)

  // const badgeClassOptions = badgeClassList.map((item) => ({ value: item._id, text: item.name }))
  const groupOptions = groupList.map((item) => ({ value: item._id, text: item.name }))

  const filteredList = recipientList.filter(item => selectedList.includes(item._id))

  useEffect(() => {
    makeServerGETRequest('/group/getGroups', {})
      .then((res) => {
        if (res.status === 200) {
          setGroupList(res.data.groups)
        }
      })
    // makeServerGETRequest('/BadgeClass/getBadgeClasses', {})
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setBadgeClassList(res.data.badgeClasses)
    //     } else {
    //       console.log('')
    //     }
    //   })
  }, [])

  useEffect(() => {
    if (selectedGroupId) {
      makeAuthenticatedServerGETRequest('/group/getGroupDetailsByGroupId', { groupId: selectedGroupId })
        .then((res) => {
          if (res.status === 200) {
            const tempRecipientIdList = []
            if (res.data.group.badgeAwardStatus) {
              for (const [key, value] of Object.entries(res.data.group.badgeAwardStatus)) {
                tempRecipientIdList.push(key)
              }
            }
            const tempRecipientList = res.data.group.participants.filter(item => !tempRecipientIdList.includes(item._id))
            setRecipientList(tempRecipientList)
            setBadgeClass(res.data.group.badgeClass)
          }
        })
    }
  }, [selectedGroupId])

  function groupChangehandler (e) {
    setSelectedGroupId(e.target.value)
  }

  // function badgeClassChangeHandler (e) {
  //   setBadgeClassId(e.target.value)
  // }

  function selectHandler (data) {
    setSelectedList((prev) => { return [...prev, data] })
  }

  function unSelecthandler (data) {
    const tempArray = [...selectedList]
    const index = selectedList.indexOf(data)
    if (index > -1) { // only splice array when item is found
      tempArray.splice(index, 1) // 2nd parameter means remove one item only
    }
    setSelectedList(tempArray)
  }

  function clearData () {
    setBadgeClass('')
    setName('')
    setImage('')
    setEvidence('')
    setNarrative('')
    window.location.reload()
  }

  function submitButtonHandler () {
    console.log('submitting now')
    console.log('selectedGroupId', selectedGroupId)
    makeAuthenticatedServerRequest('/assertion/createAssertionByGroup', 'POST', { recipientList: selectedList, badgeClassId: badgeClass._id, groupId: selectedGroupId, name, image, evidence, narrative })
      .then((res) => {
        if (res.status === 200) {
          alert('saved')
          clearData()
          window.location.reload()
        } else {
          console.log('some error')
          alert(res.data.error.message)
        }
      })
  }

  function selectAllHandler () {
    const tempArray = recipientList.map(item => item._id)
    setSelectedList(tempArray)
  }

  function unSelectAllHandler () {
    setSelectedList([])
  }
  return (
    <div className='flex flex-col sm:flex-row mx-auto w-full min-h-screen h-max bg-gray-200 relative pt-[200px] sm:pt-[80px]'>
      <div className='p-5 sm:p-10 w-screen sm:w-max h-max mx-auto bg-white rounded-lg text-start space-y-5'>
        <DefaultDropdown label='Group' onChange={groupChangehandler} options={groupOptions} />
        <div>
        <div className='font-semibold  text-lg '>Badge </div>
          {/* <div className='font-bold text-lg border-2 rounded-lg px-2'>{ badgeClass.name}</div> */}
          {badgeClass && <BadgeCardSmall id={badgeClass._id} name={badgeClass.name} description={badgeClass.description} image={badgeClass.image} criteria={badgeClass.criteria} issuer={badgeClass.issuer} createdOn={badgeClass.createdAt} />}
        </div>
      <ListSelector headingText={'Recipients'} list={recipientList} selectedList={selectedList} selectHandler={selectHandler} unSelecthandler={unSelecthandler} />
        <div className='flex justify-between border p-2 rounded-lg'>
        <button type='button' onClick={selectAllHandler} className='border rounded-lg px-2 text-white bg-green-400'>Select All</button>
        <button type='button' onClick={unSelectAllHandler} className='border rounded-lg px-2 text-white bg-red-400'>Deselect All</button>
        </div>
        {/* <DefaultDropdown label='Badge' onChange={badgeClassChangeHandler} options={badgeClassOptions} /> */}

        {/* <Textinput label='Name' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Name' />

        <Textinput label='Image URL' value={image} onChange={(e) => { setImage(e.target.value) }} placeholder='Image url' />

        <Textinput label='Evidence' value={evidence} onChange={(e) => { setEvidence(e.target.value) }} placeholder='Evidence' /> */}

        <Textinput label='Details *' value={narrative} onChange={(e) => { setNarrative(e.target.value) }} placeholder='Short Description about badge.' />

        {/* <button className='p-1 px-2 border rounded-lg bg-cyan-200' onClick={submitButtonHandler}>Submit</button> */}
      <ButtonCTA text={'Submit'} onClick={submitButtonHandler} disabled={selectedList.length === 0}/>
      </div>
      <div className=' p-10 w-screen sm:w-[470px] min-w-max h-max mx-auto my-2 bg-white rounded-lg text-start'>
      <p>Preview</p>
      {filteredList.map((item, index) => {
        return <div key={item + index} className='pl-4 pr-1 py-1 my-1 border rounded-lg flex justify-between  w-[220px] sm:w-[392px]'>
          <div className='flex flex-col'>
            <p>{item.name} </p>
            <hr></hr>
            <p>{item.email}</p>
          </div>
          <div ><button onClick={() => unSelecthandler(item._id)}><img src={CrossRedSvg} alt='x'/></button></div>
      </div>
      })}
      {/* <ButtonCTA text={'Submit'} onClick={submitButtonHandler} disabled={selectedList.length === 0}/> */}
      </div>
    </div>
  )
}
