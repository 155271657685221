import React from 'react'

/** As name suggests, shows disclaimer text on footer section of homepage. */
export default function FooterHomePageDisclaimer () {
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL

  const DisclaimerText = <p>All the information on this website - <a href={frontEndUrl}>{frontEndUrl}</a> - is published in good faith and for general information purpose only. Badge Lab does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (Badge Lab), is strictly at your own risk. Badge Lab will not be liable for any losses and/or damages in connection with the use of our website.</p>

  return <div className="flex flex-col md:flex-row sticky top-[100vh] h-max min-h-[150px] mt-2 p-2 rounded-t-[100px] bg-[#1479FF] z-20 text-center md:text-start text-white justify-center">
        <div className="flex mt-5 w-full md:w-1/3 justify-center h-max">
         <span>&#169; 2023 | </span><a href="https://dkraftlearning.com/" className="hover:underline" target="_blank" rel="noreferrer"> D.Kraft Pte. Ltd.</a>
        </div>
        <div className="flex  mt-5 w-full md:w-1/3 text-sm text-justify p-1 h-max">
            {DisclaimerText}
        </div>

        </div>
}
