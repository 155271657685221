import React from 'react'
import ButtonPrimary from '../../_atomic-design/atoms/ButtonMock/ButtonPrimary'

/**
 * @description Shows a popup in the center of the screen. Contains one Image, Some text and two buttons
 * - Proceed and Cancel. Calls onProceed or onCancel function depending on the user's input.
 * @param imgSrc - Image to be displayed
 * @param textMain - Warning text to be shown.
 * @param onProceed - function to be called when user clicks on 'Proceed' button
 * @param onCancel - function to be called when user clicks on 'Cancel' button
 */
function PopUp ({ imgSrc, textMain, onProceed, onCancel }) {
  return (
    <div className='flex flex-col sm:flex-row relative mx-auto my-auto w-full p-10 max-w-[800px] items-center rounded-3xl shadow-2xl shadow-blue-300 bg-white'>
      <div className=' w-[300px] sm:w-[400px]'><img src={imgSrc} alt='popUp' className='mx-auto' /></div>
      <div className='p-10 w-max'>
        <p className='text-center  text-2xl'>{textMain}</p>
        <div className='flex flex-col sm:flex-row gap-4 m-4'>
          <ButtonPrimary text='Proceed' onClick={onProceed} />
          <ButtonPrimary text='Cancel' onClick={onCancel} />
        </div>
      </div>
    </div>
  )
}

export default PopUp
