import React, { useState, useRef, useEffect } from 'react'
import plusIcon from '../../../assets/images/Icons/plusIcon.svg'
import minus from '../../../assets/images/Icons/minus.svg'
import Image from '../../atoms/ImageTag'
import GeneralHeading from '../../atoms/Text/GeneralHeading'

/** Shows a expandable/collapsible text area. Basically developed for showing answer or subtext when
 * user clicks on the question text (Primary text).
 */
function QuestionAddition ({ questionText, answerText }) {
  const [showAnswer, setShowAnswer] = useState(false) // stores whether answer is visible or not

  /** Using useRef hook from react enables us to directly manipulate DOM properties of element
   * to which this hook is attached. Here, we use it to change the height of answer text area.
   * When height is set to 0, the element becomes invisible.
   */
  const answerRef = useRef(null)

  /** toggles answer visibility state. Triggered when user clicks on expand/collapse icon. */
  const handleClick = () => {
    setShowAnswer(!showAnswer)
  }

  /** changes the answer height whenever showAnswer changes state. */
  useEffect(() => {
    if (showAnswer) {
      answerRef.current.style.maxHeight = '500px' // answer visible
    } else {
      answerRef.current.style.maxHeight = '0px' // answer invisible
    }

    return () => {}
  }, [showAnswer])

  return (
    <div className='p-3 sm:p-6 py-5 mb-1 w-full  rounded-lg bg-white cursor-pointer' onClick={handleClick}>
      <div className='flex justify-between items-center w-full'>
        <GeneralHeading
          text={questionText}
          className='text-sm sm:text-lg font-medium'
        />
        <Image
          src={showAnswer ? minus : plusIcon}
          className='w-3 sm:w-4 ml-2 cursor-pointer'
        />
      </div>
      <div
        className='md:w-3/4 max-h-0 overflow-hidden transition-all duration-300  '
        ref={answerRef}
      >
        <div className='w-full text-sm sm:text-base text-start'>

          {answerText}
        </div>
      </div>
    </div>
  )
}
export default QuestionAddition
