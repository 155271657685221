
const features = [
  {
    id: 1,
    headingText: 'Increased job opportunities:',
    featureText: ' Digital badges provide a way to showcase your skills and accomplishments to potential employers, giving you a competitive edge in the job market.'
  },
  {
    id: 2,
    headingText: 'Career advancement:',
    featureText: ' Digital badges can help you demonstrate your expertise and dedication, leading to opportunities for career advancement and higher pay.'
  },
  {
    id: 3,
    headingText: 'Professional recognition:',
    featureText: 'Digital badges provide a way to showcase your achievements to peers and colleagues, gaining recognition for your hard work and expertise.'
  }

]

export default features
