import React from 'react'
import ImageTag from './ImageTag'
import TickGreenCircular from '../assets/TickGreenCircular.svg'

/**
 * [key]:[value][(optional)Tick]
 * @description Creates a component to display text as [key]:[value] pairs with an optional image [Tick].
 * If 'url' param is provided, makes the 'value' field a clickable link otherwise shows plane text.
 * @param keyText - text that will be displayed as [key]
 * @param valueText - text that will be displayed as [value]
 * @param isVerified - Boolean value, controls visibility of [Tick] image
 * @param url - url
 */
function RowText ({ keyText, valueText, isVerified, url, className }) {
  return (
    <div className={`${className} flex flex-col my-1 p-2 w-full`}>
      <p className='w-max whitespace-nowrap'>{keyText}</p>
      <div className='flex flex-row'>{url
        ? <a href={`${url}`} target='_blank' rel='noreferrer' style={{ wordWrap: 'break-word' }} className='w-[90%] font-bold hover:underline '>{valueText}</a>
        : <b className='h-max break-normal'>{valueText}</b>}
      {isVerified && (
        <ImageTag src={TickGreenCircular} className='w-4 sm:w-5 mx-2' />
      )}</div>
    </div>
  )
}

export default RowText
