import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Home'
import ViewBadge from './ViewBadge'
// import Admin from './Admin/Admin'
import AddRecipient from './Admin/AddRecipient'
import AddBadgeClass from './Admin/AddBadgeClass'
import AddIssuer from './Admin/AddIssuer'
import AwardBadge from './Admin/AwardBadge'
import NoPage from './NoPage'
import AdminLogin from './Admin/AdminLogin'
import VerifyYourEmail from './CreateAccount/VerifyYourEmail'
import Redirecting from './CreateAccount/Redirecting'
import Logout from './Logout'
import Dashboard from './Badge/MyBadges'
import AuthRoute from './CreateAccount/AuthRoute'
import RequireAuth from './RequireAuth'
import RequireAuthAdmin from './RequireAuthAdmin'
import ContactUs from './Contact/ContactUs'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import { authActions } from '../store/authSlice/authSlice'
import CreateGroup from './Admin/CreateGroup'
import AwardBadgeMultiple from './Admin/AwardBadgeMultiple'
import Admin from './Admin/Admin'
import AdminDashboard from './Admin/AdminDashboard'
import ForgotPassword from './CreateAccount/ForgotPassword'
import ResetPassword from './CreateAccount/ResetPassword'

/** AppRoute gets mounted everytime someone opens our website */
function AppRoutes () {
  const dispatch = useDispatch()

  /** Read user details from cookie stored in user's browser */
  const userDetails = Cookies.get().userDetails
    ? JSON.parse(Cookies.get().userDetails)
    : null

  /** if valid user details are found in cookies, then login the user.
   * same goes for admin
   */
  if (userDetails) {
    dispatch(authActions.loginSuccess())
  }
  if (userDetails && userDetails.role === 'admin') {
    dispatch(authActions.adminLoginSuccess())
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='' element={<Home />} />
        <Route path='/Auth' element={<AuthRoute />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path='/SendVerificationMail' element={<VerifyYourEmail />} />
        <Route path='VerifyEmail' element={<Redirecting />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/dashboard' element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path='/badge/:id' element={<ViewBadge />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/admin' element={<Admin />}>
          <Route path='/admin/login' element={<AdminLogin />} />
          <Route path='/admin/Dashboard' element={<RequireAuthAdmin><AdminDashboard /></RequireAuthAdmin>} />
          <Route path='/admin/addRecipient' element={<RequireAuthAdmin><AddRecipient /></RequireAuthAdmin>} />
          <Route path='/admin/addBadgeClass' element={<RequireAuthAdmin><AddBadgeClass /></RequireAuthAdmin>} />
          <Route path='/admin/addIssuer' element={<RequireAuthAdmin><AddIssuer /></RequireAuthAdmin>} />
          <Route path='/admin/awardBadge' element={<RequireAuthAdmin><AwardBadge /></RequireAuthAdmin>} />
          <Route path='/admin/awardBadgeMultiple' element={<RequireAuthAdmin><AwardBadgeMultiple /></RequireAuthAdmin>} />
          <Route path='/admin/createGroup' element={<RequireAuthAdmin><CreateGroup /></RequireAuthAdmin>} />
        </Route>

        <Route path='/*' element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
