import React from 'react'

/** @description Creates a compact badgeClass card. Used in award badge multiple */
export default function BadgeCardSmall ({ id, name, description, image, criteria, issuer, createdOn }) {
  const createdDate = new Date(createdOn).toLocaleDateString()
  return (
    <div className='justify-around bg-blue-100 rounded-lg flex flex-col relative shadow-lg p-4  w-[90vw] sm:w-[400px] sm:h-[220px]'>
      <div className='flex flex-row h-[90%]'>
        <div className='w-1/4 flex flex-col p-1 justify-between'>
          <img src={image} alt='badge' />
          <div>{createdDate}</div>
        </div>
        <div className=' w-3/4 p-1 text-start  '>
          <div className='font-bold text-lg'>{name}</div>
          <div>Issuer : <span className='font-semibold '>{issuer.name}</span></div>
          <div>Criteria : </div>
          <div className='w-full border border-white h-[30px] p-1 font-bold whitespace-nowrap overflow-x-auto'><a href={criteria}>{criteria}</a></div>
          <div>Description : </div>
          <div className='w-full border border-white h-[30px] p-1 font-bold whitespace-nowrap overflow-x-auto'>{description}</div>
          </div>
      </div>
      <div className='flex text-xs mx-auto '>ID : {id}</div>
    </div>
  )
}
