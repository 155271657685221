import React, { useNavigate } from 'react-router-dom'

/** @description Creates a compact card that shows assertion data. Can be used to show data on dashboard. */
export default function AssertionCard ({ data }) {
  let date = new Date(data.issuedOn)
  // padding is needed to display the date and month in 2 digits e.g., 04 and not 4.
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0') // January is 0! so 1 is added to month
  const yyyy = date.getFullYear()

  date = dd + '/' + mm + '/' + yyyy // display date as dd/mm/yyyy e.g., 12/03/2023
  const navigate = useNavigate()

  /** Navigates to public page where badge can be viewed. */
  function clickHandler () {
    navigate(`/badge/${data._id}`)
  }
  return (
    <div onClick={clickHandler} className='justify-around hover:cursor-pointer  bg-gradient-to-br from-[#cbcaf7] to-[#9fdef7] hover:bg-white  transition-colors rounded-lg flex flex-col shadow-lg p-4 w-full sm:w-[400px] sm:h-[200px]'>
      <div className='flex flex-row h-[90%]'>
        <div className='w-1/2 flex flex-col justify-between'>
          <img src={data.badge.image} className='w-[90%]' alt='badge' />
        </div>
        <div className=' w-1/2 content-around space-y-3 text-start my-auto '>
          <div className='font-bold '>{data.badge.name}</div>
          <div>Issued On : <span className='font-semibold '>{date}</span></div>
          <div>Issued By : <span className='font-semibold '>{data.badge.issuer.name}</span></div>
        </div>
      </div>
      <div className='flex text-xs mx-auto  opacity-50 '>ID : {data._id}</div>
    </div>
  )
}
