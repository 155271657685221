import React, { useEffect, useState } from 'react'
import { makeAuthenticatedServerRequest, makeServerRequest } from '../../utils/helpers'
import { setAuthTokens, setUserDetails } from '../../utils/cookieStorage'
import { useDispatch } from 'react-redux'
import { authActions } from '../../store/authSlice/authSlice'
import ButtonCTA from '../../_atomic-design/atoms/ButtonMock/ButtonCTA'
import { useNavigate } from 'react-router-dom'
import Textinput from '../../_atomic-design/molecules/InputMock/TextInputGray'

const adminLoginSuccess = authActions.adminLoginSuccess

/** This page contains admin login logic */
export default function AdminLogin () {
  const [email, setEmail] = useState('') // email entered by the user
  const [password, setPassword] = useState('') // password entered by the user
  const [accessToken, setAccessToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  /** Handles login request by user. */
  function submitButtonHandler () {
    makeServerRequest('/admin/login', 'POST', { email, password })
      .then((res) => {
        if (res.status === 200) {
          alert('login successfull')
          setAccessToken(res.data.accessToken)
          setRefreshToken(res.data.refreshToken)
        } else {
          alert(' a rurr ')
        }
      })
  }

  /** This function checks cookies and retrieves user details by sending accesstokens to backend sever. */
  async function setUser () {
    // Setting the local storage tokens
    if (accessToken !== '' && refreshToken !== '') {
      setAuthTokens({ accessToken, refreshToken })

      const userDetails = await makeAuthenticatedServerRequest('/profile', 'GET')

      if (userDetails && userDetails.status === 200) {
        // Store user details in cookies so no need to make API call everytime
        setUserDetails(userDetails.data.user)
        dispatch(adminLoginSuccess({ ...userDetails.data.user, isAdminLoggedIn: true }))
        navigate('/admin/Dashboard')
      }
    }
  }

  useEffect(() => {
    setUser()
  }, [accessToken, refreshToken])

  return (
    <div className='w-full h-screen flex bg-gray-300'>
      <div className='relative bg-white mx-auto my-auto w-max border p-10 rounded-lg shadow-lg'>
        <Textinput label='Email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
        <Textinput type='password' label='Password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
        <ButtonCTA text='Submit' onClick={submitButtonHandler} className=' my-5 ' />
      </div>
    </div>
  )
}
