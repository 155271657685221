import React, { useEffect, useState } from 'react'
import { makeAuthenticatedServerGETRequest, makeAuthenticatedServerRequest, makeServerGETRequest } from '../../utils/helpers'
import Textinput from '../../_atomic-design/molecules/InputMock/TextInputGray'
import DefaultDropdown from '../../_atomic-design/molecules/InputMock/DefaultDropdown'
import AddProfilePicture from '../../containers/Common/AddProfilePicture'
import FilterBar from '../../containers/General/FilterBar'
import BadgeCard from '../../components/cards/BadgeCard'

/** This page provides functionality to add and edit badgeClass by the admin */
export default function AddBadgeClass () {
  const [IssuerList, setIssuerList] = useState([]) // stores ID of all Issuers in the database.

  const [name, setName] = useState('') // name of the badgeClass
  const [image, setImage] = useState('') // image accompanying badgeClass
  const [description, setDescription] = useState('') // description of the badgeClass
  const [criteria, setCriteria] = useState('') // criteria for awarding the badge. Store url of criteria document.
  const [issuerId, setIssuerId] = useState('') // Issuer which will award the badgeClass being created.
  const [badgeClassList, setBadgeClassList] = useState([]) // Stores all the BadgeClasses stored in the database.
  const [badgeClassId, setBadgeClassId] = useState(null) // Selected BadgeClass for editing
  const [mode, setMode] = useState('new') // mode = 'new' & 'modify'
  const [search, setSearch] = useState('') // For searching from list of badgeClasses

  // format issuers for passing to dropdown menu
  const issuerOptions = IssuerList.map((item) => ({ value: item._id, text: item.name }))

  /** Fetches the list of all badgeClasses and Issuers from database on page load. */
  useEffect(() => {
    makeAuthenticatedServerGETRequest('/BadgeClass/getBadgeClasses', {})
      .then((res) => {
        if (res.status === 200) {
          setBadgeClassList(res.data.badgeClasses)
        }
      })
    makeServerGETRequest('/issuer/getIssuers', {}).then((res) => {
      if (res.status === 200) {
        setIssuerList(res.data.issuers)
        // setIssuerId(res.data.issuers[0]._id)
      }
    })
  }, [])

  /** clears all the state variables */
  function clearData () {
    // setName('')
    // setImage('')
    // setDescription('')
    // setCriteria('')
    // setIssuerId('')
    window.location.reload() // refreshes the page to clear all state variables
  }

  /** This function is triggered when user clicks submit button. Depending on the mode,
   * makes request to create new badgeClass or edit an existing badgeClass.
   */
  function submitButtonHandler () {
    mode === 'new'
      ? (makeAuthenticatedServerRequest('/BadgeClass/createBadgeClass', 'POST', { name, image, description, criteria, issuerId })
          .then((res) => {
            if (res.status === 200) {
              alert('saved')
              clearData()
            } else {
              alert(res.data.error.message)
            }
          }))
      : (makeAuthenticatedServerRequest('/BadgeClass/modifyBadgeClass', 'put', { id: badgeClassId, name, image, description, criteria, issuerId })
          .then((res) => {
            if (res.status === 200) {
              alert('saved')
              clearData()
            } else {
              alert(res.data.error.message)
            }
          }))
  }

  /**
   * The function `selectChangeButtonHandler` sets the issuer ID based on the value of the selected
   * option in the dropdown menu.
   */
  function selectChangeButtonHandler (e) {
    setIssuerId(e.target.value)
  }

  /**
   * The function `editButtonhandler` is used to handle the click event of an edit button, which
   * scrolls to the top of the page, sets the mode to 'modify', and makes a GET request to retrieve
   * data for a specific badge class.
   */
  function editButtonhandler ({ id }) {
    window.scrollTo(0, 0) // move to top of page
    console.log('editing')
    setMode('modify')
    makeAuthenticatedServerGETRequest('/BadgeClass/getBadgeClassById', { id })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.badgeClass)
          setBadgeClassId(res.data.badgeClass._id)
          setName(res.data.badgeClass.name)
          setDescription(res.data.badgeClass.description)
          setCriteria(res.data.badgeClass.criteria)
          setIssuerId(res.data.badgeClass.issuer)
          setImage(res.data.badgeClass.image)
        }
      })
  }

  /**
   * The function `deleteButtonhandler` is used to handle the deletion of a badge class, prompting the
   * user for confirmation and making a server request to delete the badge class.
   * @returns nothing (undefined).
   */
  function deleteButtonhandler ({ id }) {
    const consent = window.confirm('Are you sure, you really want to delete this badge? This cannot be undone !')
    if (!consent) {
      return
    }
    console.log('deleting now')
    makeAuthenticatedServerRequest('/BadgeClass/deleteBadgeClass', 'delete', { id })
      .then((res) => {
        if (res.status === 200) {
          alert('Deleted')
        }
      })
    clearData()
  }

  /**
   * The function `resetButtonHandler` clears state variables.
   */
  function resetButtonHandler () {
    clearData()
  }

  return (
    <div className='flex flex-col sm:flex-row justify-around mx-auto w-full sm:h-screen bg-gray-200 relative pt-[200px] sm:pt-[80px]'>
      <div className='flex flex-col order-2 sm:order-1'>
        <FilterBar search={search} setSearch={setSearch} />
        <div className='flex flex-col order-2 sm:order-1 h-full sm:h-[90vh] mx-auto sm:mx-0 mt-2 overflow-scroll space-y-5 sm:p-10 p-2 bg-white rounded-xl'>
          {badgeClassList.map(badgeClass => {
            if (badgeClass.name.toLowerCase().includes(search.toLocaleLowerCase())) {
              return <BadgeCard
                key={badgeClass._id}
                id={badgeClass._id}
                name={badgeClass.name}
                description={badgeClass.description}
                image={badgeClass.image}
                criteria={badgeClass.criteria}
                issuer={badgeClass.issuer}
                createdOn={badgeClass.createdAt}
                onEdit={editButtonhandler}
                onDelete={deleteButtonhandler}
                />
            }
          }
          )}
        </div>
      </div>
      <div className='flex flex-col relative order-1 sm:order-2 p-10 w-max mx-auto sm:mx-0 bg-white rounded-lg text-start space-y-5'>
        <div className='absolute top-1 right-1 '><button onClick={resetButtonHandler} className='border bg-red-200 px-1 rounded-lg'>Reset</button></div>
        <div className='text-xl font-bold mx-auto w-max'>{mode === 'new' ? 'Create new Badge' : 'Editing'}</div>
        <Textinput label='Name' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Badge name' />

        {/* <Textinput label={'Image'} value={image} onChange={(e)=>{setImage(e.target.value)}} placeholder={'Image URL'} /> */}

        <Textinput label='Description' value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder='Description' />

        <Textinput label='Criteria' value={criteria} onChange={(e) => { setCriteria(e.target.value) }} placeholder='Criteria URL' />
        <p>Upload Badge Image</p>
        <AddProfilePicture profilePicture={image} setprofilePicture={setImage} />

        <DefaultDropdown label='Issuer' onChange={selectChangeButtonHandler} options={issuerOptions} defaultSelected={issuerId} />

        <button className='p-1 px-2 border rounded-lg bg-cyan-200' onClick={submitButtonHandler}>{mode === 'new' ? 'Create new Badge' : 'Modify'}</button>
      </div>
    </div>
  )
}
