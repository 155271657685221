import React, { useState, useEffect } from 'react'
import HeadingLarge from '../../_atomic-design/atoms/Text/HeadingLg'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
import ButtonPrimary from '../../_atomic-design/atoms/ButtonMock/ButtonPrimary'
import { makeServerRequest } from '../../utils/helpers'
import TextInputGray from '../../_atomic-design/molecules/InputMock/TextInputGray'
import { Link } from 'react-router-dom'

/** This page opens when user clicks on forgot password button on login page.
 * Here, user enters his email ID used during account creation. A request is sent to the backend
 * that user has forgot password and wants to set up a new password. The backend handles the rest.
 */
export default function ForgotPassword () {
  const [email, setEmail] = useState('')
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const bgStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)'

  }

  // eslint-disable-next-line no-unused-vars
  const sendPasswordResetLink = async () => {
    const res = await makeServerRequest('/auth/forgotPassword', 'POST', { email })
    if (res.status === 200) {
      alert('Link sent successfully! Please check your email inbox.')
    } else if (res.status === 404) {
      alert('Email id is not found please register.')
    } else {
      alert('problem sending the link. Please try again later or contact us at contact@dkraftlearning.com')
    }
  }

  useEffect(() => {
    // document.title = 'ForgotPassword'
  })

  return (
    <div className='w-full min-h-screen h-full flex justify-center items-center text-center' style={bgStyle}>
      <div className='w-min rounded-[20px] bg-white py-7 sm:py-10 px-6 sm:px-24 mx-3 flex flex-col items-center'>
        <HeadingLarge
          className='text-4xl text-center m-2 mt-8'
          text='Forgot Your Password'
        />
        <TextBody
          text='Enter your email and we will send you a link to reset your password'
          className='m-3 mb-5'
        />
        <TextInputGray
          className='mb-4 w-full'
          defaultValue=''
          placeholder='@mail.com'
          label=''
          id='email'
          onChange={handleEmail}
        />
        <ButtonPrimary
          text='Reset Password'
          className=' my-2 sm:my-5 bg-app-primary w-[220px] sm:w-[392px] h-7 sm:h-11 text-xs sm:text-xl block rounded-md text-black'
          onClick={sendPasswordResetLink}
        />
        <div className='flex justify-center '>
          <Link className='text-app-primary' to='/Auth?mode=login'>
            Go Back Login
          </Link>
        </div>
      </div>
    </div>
  )
}
