import React from 'react-router-dom'

/** @description Creates a compact card that shows assertion data. Can be used to show data on admin dashboard. */
export default function AssertionCardAdmin ({ data, onDelete }) {
  const date = new Date(data.issuedOn).toLocaleDateString()

  return (
    <div className='relative justify-around bg-white rounded-lg flex flex-col shadow-lg p-4 mx-2 my-2 w-full sm:w-[400px] sm:h-[200px]'>
      <div className='flex flex-row'>
        <div className='w-[100px] h-[100px] mx-auto my-auto flex '>
          <img src={data.badge.image} className='' alt='badge' />
        </div>
        <div className=' w-3/4 content-around space-y-1 text-start my-auto ml-2'>
          <div className='font-bold '>{data.badge.name}</div>
          <div>Recipient : <span className='font-semibold '>{data.recipient && data.recipient.name}</span></div>
          <div>Issued On : <span className='font-semibold '>{date}</span></div>
          <div>Issued By : <span className='font-semibold '>{data.badge.issuer.name}</span></div>
        </div>
      </div>
      <div className='flex text-xs mx-auto '>ID : {data._id}</div>
      <div className='absolute flex space-x-3 p-1 rounded-lg top-1 right-1  bg-white'>
  <button onClick={() => onDelete(data._id)} className='rounded-full'><img width={'30px'} src='https://www.svgrepo.com/show/21045/delete-button.svg' alt='del' /></button>
</div>
    </div>
  )
}
