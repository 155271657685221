import React, { useEffect, useState } from 'react'
import HeadingLarge from '../../_atomic-design/atoms/Text/HeadingLg'
import verifyEmail from '../../assets/images/Icons/verifyEmail.svg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import TextBody from '../../_atomic-design/atoms/Text/TextBody'
// import ButtonPrimaryLg from '../../_atomic-design/atoms/Button/ButtonPrimaryLg'
import { makeAuthenticatedServerRequest } from '../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ButtonPrimary from '../../_atomic-design/atoms/ButtonMock/ButtonPrimary'

/** This page is shown if a user has not verified his email id after account creation.
 * Also contains button to resend email verification link to user.
 */
export default function VerifyYourEmail () {
  const navigate = useNavigate()
  const User = useSelector((state) => state.auth)
  const [disable, setDisable] = useState(false)

  /** Sends email to logged in user containing verification link. Makes a request to server to
   * send verification link via email. */
  const sendVerificationToken = async () => {
    setDisable(true)
    const res = await makeAuthenticatedServerRequest('/auth/sendVerificationToken', 'POST')
    if (res.status === 200) {
      alert('Verification email sent')
    } else {
      alert('Something went wrong')
    }
    setDisable(false)
  }

  /** Redirects user to homepage */
  const navigateToHome = () => {
    navigate('/')
  }

  /** If a verified user opens this page, alert the user that their email is already verified and then redirect to homepage */
  useEffect(() => {
    // document.title = 'SendVerificationMail'
    if (User.isEmailVerified) {
      alert('Email already verified')
      navigate('/')
    }

    return () => {

    }
  }, [User])

  return (
    <div className='fixed w-screen h-screen bg-white/20 backdrop-blur-[20px] z-50 flex justify-center items-center gap-2'>
      <div className='w-fit mx-4 bg-white rounded-[20px] p-8 max-h-screen max-w-xl flex flex-col justify-center items-center ' style={{ boxShadow: '0 0 27px rgb(60, 56, 205, 0.15)' }}>
        <ImageTag
          src={verifyEmail}
          className='flex justify-center items-center m-4'
        />
        <HeadingLarge
          className='font-medium text-center m-2'
          text='Verify Your Email'
        />
        <TextBody
          text={<><b>Check your email and click the link</b> to activate your account </>}
          className='mb-5 text-center'
        />
        <ButtonPrimary
          text='Go to home'
          onClick={navigateToHome}
          className='w-4/5 h-11 rounded-[5px] bg-app-primary text-white text-xl'
          disabled={disable}
        />
        <div className='text-app-primary pt-8 cursor-pointer gap-2' onClick={sendVerificationToken}>
          Resend verification link
        </div>
      </div>
    </div>
  )
}
