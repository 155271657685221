import React from 'react-router-dom'
import { useEffect, useState } from 'react'
import { makeAuthenticatedServerGETRequest } from '../../utils/helpers'
// import AdminDashboardAssertionTable from '../../containers/AdminDashboardAssertionTable'
import AdminDashboardGroupTable from '../../containers/AdminDashboardGroupTable'
import InfoCard from '../../components/cards/InfoCard'
import ViewGroupDetails from '../../containers/AdminViewGroupDetails'

/** This page displays admin dahsboard */
function AdminDashboard () {
  // const [AssertionList, setAssertionList] = useState([])
  const [GroupList, setGroupList] = useState([]) // stores list of all Groups from database
  const [groupDetails, setGroupDetails] = useState(null) // stores details of one Group
  const [info, setInfo] = useState({}) // stores statistics related to number of recipients, issuers, etc

  /** Fetches list of groups and statistics from backend */
  useEffect(() => {
    makeAuthenticatedServerGETRequest('/group/getGroups', {})
      .then((res) => {
        if (res.status === 200) {
          setGroupList(res.data.groups)
        }
      })
    // makeAuthenticatedServerGETRequest('/assertion/getAssertionAll', { })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setAssertionList(res.data.foundAssertions)
    //     }
    //   })
    makeAuthenticatedServerGETRequest('/admin/fetchInfo', { })
      .then((res) => {
        if (res.status === 200) {
          setInfo(res.data.info)
        }
      })
  }, [])

  /** Fetches details of Group selected by user. */
  function viewGroupButtonClickHandler (groupId) {
    makeAuthenticatedServerGETRequest('/group/getGroupDetailsByGroupId', { groupId })
      .then((res) => {
        if (res.status === 200) {
          setGroupDetails(res.data.group)
        }
      })
  }

  return (
    <div className="flex flex-col border-3 pt-[60px] min-h-screen relative bg-gray-200 ">
      <div className='sm:absolute sm:flex sm:flex-col grid grid-cols-2 left-5 top-[60px] '>
        <InfoCard heading={'Recipients'} value={info.nRecipients} />
        <InfoCard heading={'Issuers'} value={info.nIssuers} />
        <InfoCard heading={'BadgeClasses'} value={info.nBadgeClasses}/>
        <InfoCard heading={'Badges Awarded'} value={info.nAssertions}/>
      </div>
      <div className='mx-auto my-2'>
          <AdminDashboardGroupTable GroupList={GroupList} onClick={viewGroupButtonClickHandler}/>
      </div>
      <div className='mx-auto w-max my-5 border p-2 rounded-lg text-start bg-white'>
        {groupDetails && <ViewGroupDetails groupDetails={groupDetails} />}
      </div>
      {/* <div className=' mx-auto mt-[1000px] w-screen overflow-scroll'>
        <div className='text-3xl text-bold text-start'>Assertion</div>
        <AdminDashboardAssertionTable AssertionList={AssertionList} />
      </div> */}
    </div>
  )
}

export default AdminDashboard
