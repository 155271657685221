import React, { useState, useEffect } from 'react'

import TextInputGray from '../../_atomic-design/molecules/InputMock/TextInputGray'
import HeadingLarge from '../../_atomic-design/atoms/Text/HeadingLg'
import TextXs from '../../_atomic-design/atoms/Text/TextXs'
import { useDispatch } from 'react-redux'
import { authActions } from '../../store/authSlice/authSlice'
import {
  makeAuthenticatedServerRequest,
  makeServerRequest
} from '../../utils/helpers'
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import crossSrc from '../../assets/images/Icons/cross.svg'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import { setAuthTokens, setUserDetails } from '../../utils/cookieStorage'
import ButtonPrimaryLg from '../../_atomic-design/atoms/ButtonMock/ButtonPrimary'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

const loginSuccess = authActions.loginSuccess

/** This component handles the actual logic for Logging in user, Account creation, Reset password, etc. */
export default function AuthComponent ({ className, closeModal }) {
  /** searchParams store mode i.e., login or account creation */
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  /** extract mode from url */
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState(searchParams.get('mode') || 'login')
  const [email, setEmail] = useState('') // stores user entered email
  const [password, setPassword] = useState('') // stores user entered password
  const [accessToken, setAccessToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [eyeOpen, setEyeOpen] = useState(false) // controls visibility of password on the user's screen.

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const dispatch = useDispatch()

  /** Checks whether email entered by user is a valid email or not by using regex */
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  // Helper function to validate credentials
  const validateCredentials = () => {
    /** ensures email and password fields are not empty */
    if (email === '' || password === '') {
      alert('Please fill all the fields')
      return false
    } else if (!isValidEmail(email)) {
      alert('Please provide a valid email')
      return false
    }
    return true
  }

  /** Triggered when user clicks on Submit button. First it validates credentials.
   * If mode is register then it will create new account with entered email and password.
   * If mode is login then it will log in the user. */
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!validateCredentials()) {
      return
    }
    if (mode === 'register') {
      createAccount()
    } else if (mode === 'login') {
      userLogin()
    }
  }

  /** Makes an API call to create a new account */
  const createAccount = async () => {
    const res = await makeServerRequest('/auth/register', 'POST', {
      email,
      password
    })

    if (res && res.status === 200) {
      setAccessToken(res.data.accessToken)
      setRefreshToken(res.data.refreshToken)
    } else if ((res && res.status === 409)) {
      alert('Your email is already registered with us. Kindly login')
    } else if (res && res.status !== 200) {
      alert(res.data.error.message)
    }
  }

  /** Makes an API call to login a already registered user. */
  const userLogin = async () => {
    const res = await makeServerRequest('/auth/login', 'POST', {
      email,
      password
    })
    if (res && res.status === 200) {
      setAccessToken(res.data.accessToken)
      setRefreshToken(res.data.refreshToken)
    } else if (res && res.status === 401) {
      alert('Please check your email and password and try again')
    } else if (res && res.status === 404) {
      alert('User not found please signup.')
    } else if (res && res.status !== 200) {
      alert(res.data.error.message)
    }
  }

  /** change password visibility to user */
  const passwordEyeToggle = () => {
    setEyeOpen(!eyeOpen)
  }

  async function setUser () {
    // Setting the local storage tokens
    if (accessToken !== '' && refreshToken !== '') {
      setAuthTokens({ accessToken, refreshToken })

      const userDetails = await makeAuthenticatedServerRequest('/profile', 'GET')

      if (userDetails && userDetails.status === 200) {
        // Store user details in cookies so no need to make API call everytime
        setUserDetails(userDetails.data.user)
        dispatch(loginSuccess({ ...userDetails.data.user, isLoggedIn: true }))
      }
      if (mode === 'login') {
        if (!userDetails.data.user.isEmailVerified) {
          navigate('/SendVerificationMail')
          return
        }
        // if (!userDetails.data.user.detailsCompleted) {
        //   navigate('/AddMoreDetails')
        //   return
        // }
        if (location.state && location.state.redirectBack) {
          navigate(location.state.redirectBack)
        } else {
          navigate('/dashboard')
        }
      } else if (mode === 'register') {
        // Send the verification email
        await makeAuthenticatedServerRequest('/auth/sendVerificationToken', 'POST')
        navigate('/SendVerificationMail')
      }
    }
  }

  useEffect(() => {
    setUser()
  }, [accessToken, refreshToken])

  useEffect(() => {
    setMode(searchParams.get('mode') || 'login')
    // document.title = searchParams.get('mode') || 'login'
  }, [searchParams])

  return (
    <div className={'w-full max-w-[326px] sm:max-w-xl rounded-[11px] sm:rounded-[20px] bg-white py-7 sm:py-10 ' + className}>
      <div className='w-min mx-auto'>
        {
            closeModal && <ImageTag src={crossSrc} onClick={closeModal} className='h-5 ml-auto relative -top-3 -right-2 sm:-right-16 cursor-pointer ' />
          }
        <HeadingLarge
          className='text-center'
          text={mode === 'register' ? 'Create Account' : 'Login'}
        />
        <div className='flex flex-row justify-center gap-4 my-5 '>
          {/* <Icon href={GOOGLE_LOGIN} src={google} rel="noreferrer" />
              <Icon href={FACEBOOK_LOGIN} src={Facebook} rel="noreferrer" />
              <Icon href={LINKEDIN_LOGIN} src={Linkedin} rel="noreferrer" /> */}
        </div>
        <form onSubmit={handleSubmit}>
          <TextInputGray
            className='mb-4'
            defaultValue=''
            placeholder='@mail.com'
            label='Email Address'
            id='email'
            onChange={handleEmail}
          />
          <div className='relative'>
            <TextInputGray
              className='block mt-4 '
              type={(eyeOpen === false) ? 'password' : 'text'}
              label='Password'
              placeholder={(eyeOpen === false) ? '********' : 'password'}
              on
              onChange={handlePassword}
            />
            <div className='text-2xl absolute top-6 sm:top-10 right-5'>
              {
                          (eyeOpen === false)
                            ? <AiFillEye onClick={passwordEyeToggle} />
                            : <AiFillEyeInvisible onClick={passwordEyeToggle} />

                      }
            </div>
          </div>
          {
                mode === 'register' &&
                  <TextXs
                    className='mt-2 text-app-grey-light'
                    text='Must be 8 characters at least'
                  />
            }
          <ButtonPrimaryLg text={`${mode === 'login' ? 'Login' : 'Create Account'}`} onClick={handleSubmit} className='my-2 sm:my-5' />
          <div className='flex flex-col justify-center items-center gap-y-2 '>
            {
                mode === 'login' &&
                  <Link className='text-app-primary text-xs sm:text-base' to='/ForgotPassword'>
                    Forgot Password
                  </Link>
              }
            <span className='text-xs sm:text-base'>
              {mode === 'login' ? 'Don’t have an account? ' : 'Already have an account? '}
              {
                  mode === 'login'
                    ? <Link to='/Auth?mode=register' className='text-app-primary font-semibold'> Create Account</Link>
                    : <Link to='/Auth?mode=login' className='text-app-primary font-semibold'> Login</Link>
                }
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}
