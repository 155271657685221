import React from 'react'
import TestimonialCarousel from '../containers/Home/TestimonialCarousel'

/** @description Creates Testimonials section in Homepage. */
function Testimonials () {
  return (
    <div id='testimonials' className=' flex justify-center '>

      <TestimonialCarousel />

    </div>
  )
}
export default Testimonials
