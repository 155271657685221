import React from 'react'
import bgFaq from '../../assets/images/bgFaq.svg'
// import ButtonCTA from '../../_atomic-design/atoms/ButtonMock/ButtonCTA'
import badgeLanding from '../../assets/images/badgeLanding.png'

/** This is the first thing a user sees when opens our website */
export default function Landing () {
  const bgImage = {
    backgroundImage: `url(${bgFaq})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <div id='home' className='min-h-screen h-full relative pt-24 md:pt-32 pb-24 rounded-bl-[20px] rounded-br-[20px] overflow-hidden' style={bgImage}>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-y-8 md:gap-y-12 mx-auto w-11/12 h-full'>
        <div className='flex flex-col justify-center items-start md:items-center md:pt-16 h-full order-2 md:order-1 w-11/12 text-center md:text-left '>
          <div className='ml-5 mb-10 z-10 w-fit'>
            <h1 className='font-bold text-[32px] md:text-6xl text-[#6042D1] md:mb-5'>
              Earn Digital Badges to Showcase Your Skills and Achievements.
            </h1>
            <p className='md:text-xl text-lg mb-5'>
              Our digital badge provider empowers individuals to showcase their skills, knowledge, and achievements to potential employers and peers. We offer a range of badges in various industries, from technology to healthcare to education. Our badges are portable and verifiable, and offer a meaningful way to showcase your expertise and accomplishments.
            </p>
            {/* <ButtonCTA text='Start' /> */}
          </div>
        </div>
        <div className='h-full flex justify-center items-center w-full mx-auto order-1 md:order-2 '>
          <ImageComponent />
        </div>
      </div>

    </div>
  )
}

function ImageComponent () {
  return (
    <div className='w-full max-w-[520px] rounded-2xl p-3 md:p-5 z-10 backdrop-blur-[34px] bg-white/40 shadow-md md:shadow-xl animate-bounce-slow'>
      <img src={badgeLanding} alt='badge' />
    </div>
  )
}
