import React from 'react'

/** @description Creates a button with background of color gradient and white text. */
export default function ButtonCTA ({ className, onClick, text, disabled }) {
  return (
    <button
      onClick={onClick}
      className={` ${className}  w-max px-10 h-12 rounded-md text-white ${disabled ? ' cursor-not-allowed bg-app-dark-grey ' : ' cursor-pointer bg-gradient-to-r from-[#1382fe] to-[#08c8f6] '}`}
      disabled={disabled}
    >
      {text}
    </button>
  )
}
