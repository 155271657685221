import React from 'react'

/** Displays customized <hr></hr> Horizontal Rule as shown below.
 * ------  SOME TEXT  -------
 * @param text - Text that needs to be displayed in between <hr>
 */
export default function CustomHr ({ text }) {
  return (
    <div className='flex justify-between items-center my-3 sm:my-6 text-gray-500 '>
      <div className='h-px w-3/5 sm:w-11/12 bg-app-grey'><hr /></div>
      <span className='w-full text-xs  text-center'>{ text }</span>
      <div className='h-px w-3/5 sm:w-11/12 bg-app-grey'><hr /></div>
    </div>
  )
}
