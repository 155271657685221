/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import bgFaq from '../../assets/images/bgFaq.svg'
// import HomeNavbar from '../../_atomic-design/molecules/Navbars/HomeNavbar'
import Navbar from '../../components/Navbar'
import PrivacyNPolicyModal from '../../containers/Modals/PrivacyNPolicyModal'
import { makeServerRequest } from '../../utils/helpers'
import ButtonPrimary from '../../_atomic-design/atoms/ButtonMock/ButtonPrimary'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

/** This page opens when user clicks on Contact Us button. Handles email, phone and message
 * entered by user and sends it to the backend. */
export default function ContactUs () {
  const [value, setValue] = useState()
  const [name, setName] = useState('') // name entered by user
  const [email, setEmail] = useState('') // email entered by user
  const [phone, setPhone] = useState() // phone number entered by user
  const [message, setMessage] = useState('') // message entered by user for our support team
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false)
  const [agree, setAgree] = useState(false) // user's agreement to our terms and conditions.
  const [showPrivacyNPolicy, setShowPrivacyNPolicy] = React.useState(false)

  /** Checks whether email entered by user is a valid email or not by using with regex */
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const checkboxHandler = () => {
    const phoneNumber = phone || 0
    const Email = email || 'null@g.com'

    if ((phoneNumber === 0) && (Email === 'null@g.com')) {
      alert('Phone or Email required')
    } else {
      if (!isValidEmail(Email)) {
        alert('Invalid Email please provide a valid email')
      } else {
        setAgree(!agree) // Don't miss the exclamation mark
      }
    }
  }

  /** Triggered when user clicks Submit button. After validating user's input sends it to backend. */
  const handleSubmit = async (e) => {
    e.preventDefault()
    const phoneNumber = phone || 0
    const Email = email || 'null@g.com'

    if ((phoneNumber === 0) && (Email === 'null@g.com')) {
      setAgree(!agree)
      alert('Phone or Email required')
      return
    }
    const res = await makeServerRequest('/support/', 'POST', {
      name,
      email: Email,
      phoneNumber,
      message
    })
    if (res.status === 200) {
      alert('Your message has been sent successfully!')
      setName('')
      setEmail('')
      setPhone(null)
      setMessage('')
    } else {
      alert('Something went wrong. Please try again later.')
    }
    setDisabled(false)
  }

  const bgStyle = {
    backgroundImage: `url(${bgFaq})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
  useEffect(() => {
    // document.title = 'ContactUs'
  }, [])
  return (
    <>
      <PrivacyNPolicyModal active={showPrivacyNPolicy} setActive={() => setShowPrivacyNPolicy(!showPrivacyNPolicy)} />
      <div
        className='w-full min-h-screen pt-28 overflow-hidden'
        style={bgStyle}
      >
        <Navbar selected='/ContactUs' white />
        <div className=' bg-white w-11/12 sm:w-4/5 mx-auto overflow-hidden mb-8'>
          <div className=' max-w-7xl mx-auto lg:grid lg:grid-cols-5'>
            <div className='bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12'>
              <div className='max-w-lg mx-auto'>
                <h2 className='text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl'>
                  Contact Us
                </h2>
                <dl className='mt-8 text-base text-gray-500'>
                  {/* <div className="mb-6">
                                    <dt className="text-black font-bold mb-3">
                                        Our Registered Address
                                    </dt>
                                    <dd>
                                        <p>EDTECH KRAFT PRIVATE LIMITED</p>
                                        <p>Kuzhimattathil Chambers,</p>
                                        <p>Bld No. VI/726 B2, Kochi,</p>
                                        <p>Kozhikode, Kerala, India, 682021</p>
                                    </dd>
                                </div> */}
                  <div>
                    {/* <dt className="text-black font-bold mb-3">
                                        Our Corporate Address
                                    </dt> */}
                    <dd>
                  <p>
                          EDTECH KRAFT PRIVATE LIMITED
                                        </p>
                  <p>
                          Room No 502, IIITD Incubation
                          Center,
                                        </p>
                  <p>
                          Indraprastha Institute of
                          Information Technology
                                        </p>
                  <p>Delhi, Okhla Phase-3,</p>
                  <p>New Delhi 110020</p>
                </dd>
                  </div>
                  <div className='mt-3'>
                    <dt className='sr-only'>Email</dt>
                    <dd className='flex'>
                  <svg
                          className='flex-shrink-0 h-6 w-6 text-gray-400'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          aria-hidden='true'
                        >
                          <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                            />
                        </svg>
                  <span className='ml-3'>
                          {' '}
                          <a href='mailto:contact@dkraftlearning.com' className='text-blue-600 underline'> contact@dkraftlearning.com </a>{' '}
                        </span>
                </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className='bg-white py-2 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12'>
              <div className='max-w-lg mx-auto lg:max-w-none'>
                <form
                  className='grid grid-cols-1 gap-y-6'
                  onSubmit={handleSubmit}
                >
                  <h2 className='text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl'>
                    Send your query
                                </h2>
                  <div>
                    <label
                  htmlFor='full-name'
                  className='sr-only'
                >
                                      Full name
                </label>
                    <input
                  type='text'
                  name='full-name'
                  id='full-name'
                  autoComplete='name'
                  className='block w-full shadow-sm bg-[#F4F7F9] rounded-lg py-3 px-4 outline-none focus:border focus:border-blue-600'
                  placeholder='Full name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                  </div>
                  <div>
                    <label htmlFor='email' className='sr-only'>
                  Email
                                    </label>
                    <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  className='block w-full shadow-sm bg-[#F4F7F9] rounded-lg py-3 px-4 outline-none focus:border focus:border-blue-600'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                  </div>
                  <div>
                    <label htmlFor='phone' className='sr-only'>
                  Phone
                                    </label>
                    <div className='flex flex-row gap-2'>
                  <PhoneInput
                          placeholder='Phone'
                          defaultCountry='IN'
                          value={phone}
                          className='flex flex-row gap-2 h-full w-full shadow-sm bg-[#F4F7F9] rounded-lg py-3 px-4 outline-none focus:border focus:border-blue-600 border'
                          onChange={setPhone}
                        />
                </div>
                  </div>
                  <div>
                    <label
                  htmlFor='message'
                  className='sr-only'
                >
                                      Message
                </label>
                    <textarea
                  id='message'
                  name='message'
                  rows='4'
                  className='block w-full shadow-sm bg-[#F4F7F9] rounded-lg py-3 px-4 outline-none focus:border focus:border-blue-600 border'
                  placeholder='Message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
                  </div>
                  <div className=''>
                    <input type='checkbox' id='agree' checked={agree} onChange={checkboxHandler} className='mr-1' />
                    <label htmlFor='agree'> I agree to <b className='cursor-pointer text-blue-500 ' onClick={() => setShowPrivacyNPolicy(!showPrivacyNPolicy)}>Privacy Policy</b></label>
                  </div>
                  <div className='w-full'>
                    <ButtonPrimary className='mx-auto md:ml-0' text='Submit' disabled={!agree} />
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
