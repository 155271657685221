import React, { useEffect, useState } from 'react'
import { makeAuthenticatedServerRequest, makeServerGETRequest } from '../../utils/helpers'
import TextInputGray from '../../_atomic-design/molecules/InputMock/TextInputGray'
import IssuerCard from '../../components/cards/IssuerCard'

/** Page to add or edit an Issuer */
export default function AddIssuer () {
  const [name, setName] = useState('') // stores name of Issuer
  const [email, setEmail] = useState('') // stores email of Issuer
  const [url, setUrl] = useState('') // stores url of official website of Issuer
  const [description, setDescription] = useState('') // stores description about the Issuer
  const [image, setImage] = useState('') // stores url of logo of the Issuer
  const [IssuerList, setIssuerList] = useState([]) // stores list of all Issuers
  const [id, setId] = useState(null) // ID of Issuer when in editing mode
  const [mode, setMode] = useState('new') // mode = 'new' & 'modify'

  /** Gets list of all Issuers from database on page load. */
  useEffect(() => {
    makeServerGETRequest('/issuer/getIssuers', {}).then((res) => {
      if (res.status === 200) {
        setIssuerList(res.data.issuers)
      }
    })
  }, [])

  /** This function clears all state variables by refreshing the page. */
  function clearData () {
    // setName('')
    // setEmail('')
    // setUrl('')
    // setDescription('')
    // setImage('')
    window.location.reload() // refreshes page
  }

  /** This function is triggered when user pressed submit button. Depending on the mode,
   * makes request to update or create a new Issuer. */
  function submitButtonHandler () {
    mode === 'new'
      ? makeAuthenticatedServerRequest('/issuer/createIssuer', 'POST', { email, name, description, image, url })
        .then((res) => {
          if (res.status === 200) {
            alert('saved')
          } else {
            alert(res.data)
          }
          clearData()
        })
      : makeAuthenticatedServerRequest('/issuer/modifyIssuer', 'put', { id, email, name, description, image, url })
        .then((res) => {
          if (res.status === 200) {
            alert('saved')
          } else {
            alert(res.data)
          }
          clearData()
        })
  }

  /** Changes mode to edit an existing Issuer. Requests details for Issuer selected by the user. */
  function editButtonhandler ({ id }) {
    window.scrollTo(0, 0) // scroll to top of page
    setMode('modify')
    setId(id)
    makeServerGETRequest('/issuer/getIssuerById', { IssuerId: id }).then((res) => {
      if (res.status === 200) {
        setName(res.data.issuer.name)
        setEmail(res.data.issuer.email)
        setUrl(res.data.issuer.url)
        setDescription(res.data.issuer.description)
        setImage(res.data.issuer.image)
      }
    })
  }

  /** Function to delete an existing Issuer. */
  function deleteButtonHandler ({ id }) {
    const consent = window.confirm('Are you sure you want to delete this Issuer? Deleting this will render any badges issued by this Issuer useless.')
    if (!consent) {
      return
    }
    makeAuthenticatedServerRequest('/issuer/deleteIssuer', 'delete', { id })
      .then((res) => {
        if (res.status === 200) {
          alert('Deleted Successfully')
          window.location.reload()
        } else {
          alert('Some error occured')
        }
      })
  }

  return (
    <div className='mx-auto flex flex-col sm:flex-row justify-around   w-full h-full sm:h-screen  bg-gray-200 relative pt-[200px] sm:pt-[80px]'>
      <div className='flex flex-col order-2 sm:order-1 h-full sm:h-[90vh] mx-auto sm:mx-0 overflow-scroll space-y-5 sm:p-10 p-2 bg-white rounded-xl'>
        { IssuerList.map((issuer) => <IssuerCard onEdit={editButtonhandler} onDelete={deleteButtonHandler} key={issuer._id} id={issuer._id} name={issuer.name} email={issuer.email} url={issuer.url} description={issuer.description} image={issuer.image}/>)}
      </div>
      <div className=' flex flex-col order-1 sm:order-2  p-10 w-max h-max mx-auto sm:mx-0 my-10 bg-white rounded-lg text-start space-y-5'>
        <TextInputGray label='Name' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Issuer name' />
        <TextInputGray label='Email' value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='Issuer Email' disabled={mode === 'modify'}/>
        <TextInputGray label='Issuer Website' value={url} onChange={(e) => { setUrl(e.target.value) }} placeholder='Website URL' />
        <TextInputGray label='Description' value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder='Description' />
        <TextInputGray label='Issuer Logo Url' value={image} onChange={(e) => setImage(e.target.value)} placeholder='Issuer Logo URL' />
        <button className='p-1 px-2 border rounded-lg bg-cyan-200' onClick={submitButtonHandler}>{mode === 'new' ? 'Create new Issuer' : 'Modify'}</button>
      </div>
    </div>
  )
}
