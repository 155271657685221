import React from 'react'
import Navbar from '../components/Navbar'
import FAQs from '../components/FAQSection'
import Landing from '../containers/LandingSection/Landing'
import Features from '../containers/LandingSection/Features'
import Testimonials from '../components/Testimonials'
import FooterHomePageDisclaimer from '../containers/Home/FooterHomePageDisclaimer'

/** This function displays the landing page. Whole page is divided into various sections
 * Navbar is on top. Sections such as landing, features, testimonials are present in between.
 * Footer section at the bottom.See individual section definitions for more details.
 */
function Home (props) {
  return (
    <div className='w-auto'>
      <Navbar />
      <h1>There is no place like Home :)</h1>
      <Landing />
      <Features />
      <Testimonials />
      <FAQs />
      <FooterHomePageDisclaimer />
    </div>
  )
}

export default Home
