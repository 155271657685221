import React from 'react'
import EditAndDeleteButtons from '../EditAndDeleteButtons'

/** @description Creates a card that shows BadgeClass data.Used in add badgeClass in admin. */
export default function BadgeCard ({ id, name, description, image, criteria, issuer, createdOn, onEdit, onDelete }) {
  const createdDate = new Date(createdOn).toLocaleDateString()
  return (
    <div className='justify-around bg-blue-100 rounded-lg flex flex-col relative shadow-lg p-4  w-[90vw] sm:w-[500px] sm:h-[250px]'>
      <div className='flex flex-row h-[90%]'>
        <div className='w-1/4 flex flex-col p-1 justify-between'>
          <img src={image} alt='badge' />
          <div>{createdDate}</div>
        </div>
        <div className=' w-3/4 p-1 text-start  '>
          <div className='font-bold text-xl'>{name}</div>
          <div>Issuer : <span className='font-semibold '>{issuer.name}</span></div>
          <div>Criteria : </div>
          <div className='w-full border border-white h-[30px] p-1 font-bold whitespace-nowrap overflow-x-auto'><a href={criteria}>{criteria}</a></div>
          <div>Description : </div>
          <div className='w-full border border-white h-[30px] p-1 font-bold whitespace-nowrap overflow-x-auto'>{description}</div>
          </div>
      </div>
      <div className='flex text-xs mx-auto '>ID : {id}</div>
      <EditAndDeleteButtons id={id} onEdit={onEdit} onDelete={onDelete} />
      {/* <div className='absolute flex space-x-3 p-1 rounded-lg top-1 right-1  bg-white'>
        <button onClick={() => onEdit({ id })} className='rounded-full'><img width={'30px'} src='https://www.svgrepo.com/show/73131/edit-button.svg' alt='edit' /></button>
        <button onClick={() => onDelete({ id })} className='rounded-full'><img width={'30px'} src='https://www.svgrepo.com/show/21045/delete-button.svg' alt='del' /></button>
      </div> */}
    </div>
  )
}
