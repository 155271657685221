import React from 'react'

/**
 * @description Displays a dropdown.
 * @param options - [{text: 'text to be displayed to user', value: 'value of this selected option'}]
 * @param onChage - Function that will be execute when the user selects a value.
 * @param className - additional styles that will be applied on the outermost div of this JSX
 */
export default function DefaultDropdown ({
  id,
  name,
  label,
  options,
  className,
  onChange,
  defaultSelected
}) {
  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>
      <select
        className='bg-[#F4F7F9] w-full text-sm block mx-auto p-4 h-14 border-none rounded-lg text-app-dark-black'
        name={name}
        id={id}
        onChange={onChange}
      >
        <option value='' disabled selected hidden>
          Please Select
        </option>
        {options &&
                    options.map((item, index) => (
                      <option key={index} value={item.value} selected={defaultSelected === item.value}>
                        {item.text}
                      </option>
                    ))}
      </select>
    </div>
  )
}
