import React from 'react'
import ImageTag from '../../_atomic-design/atoms/ImageTag'
import searchImg from '../../assets/images/Icons/Search.svg'
import GeneralTextInput from '../../_atomic-design/molecules/InputMock/GeneralTextInput'

/** Shows search bar. Sets the text entered by user as "search" by calling "setSearch" */
export default function FilterBar ({ className, search, setSearch }) {
  return (
      <div className={` ${className} flex flex-col sm:flex-row border-b border-app-grey pb-5`}>
          <div className="flex flex-row mt-5 w-full">
            <ImageTag src={searchImg} className="w-3" />
        <GeneralTextInput className={'ml-4 border-none outline-none w-full border bg-transparent'} value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder={'search'} />
          </div>
      </div>
  )
}
