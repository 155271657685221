import React from 'react'
import QuestionAddition from '../_atomic-design/molecules/Others/QuestionAddition'

import bgFaq from '../assets/images/bgFaq.svg'
import HeadingXlg from '../_atomic-design/atoms/Text/HeadingXlg'
import questions from '../assets/data/faqData.js'

/** This function creates the entire FAQ section. */
function AddQuestions () {
  const FAQs = questions // FAQ questions and respective answers are stored in a file as an array. Imported file used here.

  // defines background image and its properties for the entire FAQ section.
  const style = {
    backgroundImage: `url(${bgFaq})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }
  return (
    <div
      id='faq'
      className='min-h-screen py-28 rounded-[20px] '
      style={style}
    >
      <div className='w-max mx-auto text-center mb-10 sm:mb-10 px-3 py-2 rounded-lg backdrop-blur-md'>
        <HeadingXlg
          text='Answers to Your Curiosity'
          className='mb-4 text-[#6042D1]   '
        />
      </div>
      <div className='w-4/5  mx-auto'>
        {FAQs.map((ques, key) => {
          return (
            <div
              key={key}
              className=' gap-4 bg-white/100 rounded-lg my-10'
            >
              <QuestionAddition
                key={key}
                questionText={ques.questionText}
                answerText={ques.answerText}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default AddQuestions
