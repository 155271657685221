import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import logoIcon from '../assets/images/Icons/LogoIcon4.png'
import cross from '../assets/images/Icons/cross.svg'
import NavIcon from '../assets/images/Icons/NavIconPhone.svg'

/**
 * @description Navigation bar shown to any user visiting our page. Changes it's state based on
 * authenticated user and the device on which it is displayed. For mobile users, bydefault it shows
 * collapsed state. User can expand it to see the links present in this navigation bar. */
export default function Navbar ({ selected }) {
  /** Check if a user is logged in or not. */
  const loggedIn = useSelector((state) => state.auth.isLoggedIn)

  /** Used to higlight the presently open Link in the navbar. */
  const [active, setActive] = useState(selected || 'Home')

  /**
   * Controls the height of navbar.In Mobile screens,the Navbar has two states, one open and other closed.
   * In open mode, the navbar's height is increased to show all the links available.
   * In closed mode, only a clickable icon is shown that expands the navbar when clicked.
  */
  const [isNavBarOpen, setIsNavBarOpen] = useState(false)

  /**
   * Array contains all the links that are shown on the Navbar. The links with "to:/#something" are hashlinks
   * which are used for in-page navigation. Other links are links to different pages on this site.
   * "Protected" parameter is used to control visibility to un-authenticated users. For example- "Dashboard"
   * is shown only after a user logs in.
   */
  const pageSectionLinks = [
    { name: 'Home', to: '/#', protected: false, id: 'home_ride' },
    { name: 'Features', to: '/#features', protected: false, id: 'features_ride' },
    { name: 'Testimonials', to: '/#testimonials', protected: false, id: 'testimonials_ride' },

    { name: 'FAQ', to: '/#faq', protected: false, id: 'faq_ride' },
    { name: 'Contact Us', to: '/ContactUs', protected: false, id: 'contactus_ride' },
    { name: 'Dashboard', to: '/Dashboard', protected: true, id: 'dashboard_ride' }
  ]

  /** Function to toggle Navbar visibility in mobile screens (smaller screens) */
  function changeNavBarVisibility () {
    setIsNavBarOpen((prev) => { return !prev })
  }

  return (
    <div className={(isNavBarOpen ? ' h-max' : ' h-[60px] ') + ' w-full px-2  bg-gray-100 text-black fixed top-0 z-50 flex flex-col sm:flex-row justify-between overflow-hidden'}>
      <div className='flex sm:mx-10 my-auto justify-between '>
        <Link to='/' className='my-auto '><img src={logoIcon} alt='logo' className='w-32' /></Link>
        <div className='my-auto sm:hidden' onClick={changeNavBarVisibility}>
          {
          isNavBarOpen
            ? <img src={cross} alt='cross' className='w-10 h-10' />
            : <img src={NavIcon} alt='navicon' className='w-10 h-10' />
        }
        </div>
      </div>
      <div className=' my-auto sm:mx-10  py-3 flex flex-col sm:flex-row text-start'>
        {pageSectionLinks.map((item, key) => {
          return (!item.protected || item.protected === loggedIn
            ? (<div key={key}>
              <NavLink
                name={item.name}
                to={item.to}
                rideId={item.id}
                active={active}
                setActive={setActive}
                changeNavBarVisibility={changeNavBarVisibility}
              />
               </div>)
            : null
          )
        })}
        <div className='flex mx-2'>
        {loggedIn
          ? <Link to='/logout'>Logout</Link>
          : <Link to='/Auth?mode=login'>Login</Link>}
        </div>
      </div>
    </div>
  )
}

function NavLink ({ active, setActive, name, to, rideId, changeNavBarVisibility }) {
  const onClick = () => {
    setActive(to)
    changeNavBarVisibility()
  }
  return (
    <>
      <HashLink id={rideId} className={` cursor-pointer  w-max mx-2 ${active === to ? 'font-bold' : ''} `} to={to} smooth onClick={onClick}>
        {name}
      </HashLink>
    </>
  )
}
