import React from 'react'

/** @description Shows a text input area. */
export default function Textinput ({
  id,
  defaultValue,
  value,
  label,
  placeholder,
  onChange,
  type,
  className,
  required,
  disabled
}) {
  return (
    <div className={`${className} flex flex-col`}>
      <label className='flex text-xs sm:text-base mb-1' htmlFor={id}>
        {label}
      </label>
      <input
        type={type || 'text'}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        className='bg-[#F4F7F9] flex rounded-lg w-[220px] sm:w-[392px] p-4 h-7 sm:h-[50px] placeholder:text-xs sm:placeholder:text-base'
        defaultValue={defaultValue}
        value={value}
        required={required}
        disabled={disabled}
      />
    </div>
  )
}
