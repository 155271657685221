import React from 'react'
import { testimonials } from '../../assets/data/testimonialData'

export default function TestimonialCarousel () {
  // We will start by storing the index of the current image in the state.
  const [currentImage, setCurrentImage] = React.useState(0)

  // We are using react ref to 'tag' each of the images. Below will create an array of
  // objects with numbered keys. We will use those numbers (i) later to access a ref of a
  // specific image in this array.
  const refs = testimonials.reduce((acc, val, i) => {
    acc[i] = React.createRef()
    return acc
  }, {})

  const scrollToImage = i => {
    // First let's set the index of the image we want to see next
    setCurrentImage(i)
    // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
    // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
    // your current view! To do so we pass an index of the image, which is then use to identify our current
    // image's ref in 'refs' array above.
    refs[i].current.scrollIntoView({
      //     Defines the transition animation.
      behavior: 'smooth',
      //      Defines vertical alignment.
      block: 'nearest',
      //      Defines horizontal alignment.
      inline: 'start'
    })
  }

  // Some validation for checking the array length could be added if needed
  const totalImages = testimonials.length

  // Below functions will assure that after last image we'll scroll back to the start,
  // or another way round - first to last in previousImage method.
  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0)
    } else {
      scrollToImage(currentImage + 1)
    }
  }

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1)
    } else {
      scrollToImage(currentImage - 1)
    }
  }

  // Tailwind styles. Most importantly notice position absolute, this will sit relative to the carousel's outer div.
  const arrowStyle =
    'absolute text-slate-500 text-3xl z-8 bg-transparent h-10 w-10 rounded-full flex items-center justify-center border-slate-500 border-2'

  // Let's create dynamic buttons. It can be either left or right. Using
  // isLeft boolean we can determine which side we'll be rendering our button
  // as well as change its position and content.
  const sliderControl = isLeft => (
    <button
      type='button'
      onClick={isLeft ? previousImage : nextImage}
      className={`m-4 ${arrowStyle} ${isLeft ? 'right-48 md:right-32' : 'right-16 md:right-16'}`}
      style={{ top: '84%' }}
    >
      <span role='img' className='pb-2' aria-label={`Arrow ${isLeft ? 'right' : 'right'}`}>
        {isLeft ? '←' : '→'}
      </span>
    </button>
  )

  return (
  // Images are placed using inline flex. We then wrap an image in a div
  // with flex-shrink-0 to stop it from 'shrinking' to fit the outer div.
  // Finally the image itself will be 100% of a parent div. Outer div is
  // set with position relative, so we can place our cotrol buttons using
  // absolute positioning on each side of the image.
    <div className='py-8 md:m-16 flex justify-center w-10/12 items-center'>
      <div className='relative w-full'>
        <div className='carouseltestimonial w-full'>
          {testimonials.map((testimonial, i) => (
            <div className={`w-full flex-shrink-0 rounded-[50px] bg-[${testimonial.bgcolor}]  `} key={testimonial.img} ref={refs[i]}>
              <figure className='relative md:flex rounded-xl p-8 md:p-0 md:m-20 dark:bg-slate-800 '>
                <div className='absolute invisible md:visible  left-60 top-48 rounded-full p-2 m-1 bg-pink-300 text-gray-100  w-32 h-32 flex items-center justify-center text-xl font-bold opacity-30' />
                <div className='absolute invisible md:visible  right-96 top-32  rounded-full p-2 m-1 bg-blue-300 text-gray-100  w-24 h-24 flex items-center justify-center text-xl font-bold opacity-30' />
                <div className='absolute invisible md:visible  right-14 top-[-20%]  rounded-full p-2 m-1 bg-gray-300 text-gray-100  w-20 h-20 flex items-center justify-center text-xl font-bold opacity-30' />
                <div className='absolute invisible md:visible  left-[40%] top-[-40%]  rounded-full p-2 m-1 bg-green-300 text-gray-100  w-24 h-24 flex items-center justify-center text-xl font-bold opacity-30' />
                <div className='absolute invisible md:visible  left-64 top-[-50px]  rounded-full p-2 m-1 bg-red-400 text-gray-100  w-14 h-14 flex items-center justify-center text-xl font-bold opacity-30' />

                <img className='w-32 h-32 md:w-48 md:h-48  mx-auto ' src={testimonial.img} alt='' width='384' height='512' />
                <div className='pt-6 md:p-8 text-center md:text-left space-y-4 mb-12'>
                  <blockquote>
                    <p className='text-lg first-letter:text-4xl font-bold text-slate-500'>
                      {testimonial.mesage}
                    </p>
                  </blockquote>
                  <figcaption className='md:text-right md:text-xl font-bold'>
                    <div className='text-slate-700'>
                      {testimonial.name}
                    </div>
                    <div className='text-slate-600'>
                      {testimonial.degignation}
                    </div>
                  </figcaption>
                </div>
              </figure>
              {sliderControl(true)}
              {sliderControl()}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
