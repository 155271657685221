import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

/** This function protects child component from rendering to unauthenticated users.
 * This function checks if a user opening the page is admin in or not. If the person
 * is not logged in, it redirects the user to login page and after login, the user is
 * redirected to its initial jorney.
 */
export default function RequireAuthAdmin ({ children }) {
  const authState = useSelector((state) => state.auth)
  const location = useLocation()

  if (!authState.isAdminLoggedIn) {
    return <Navigate to='/admin/login' state={{ redirectBack: location }} replace />
  }
  return (
    <>
      {children}
    </>
  )
}
