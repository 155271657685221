import React, { useEffect, useState } from 'react'
import AssertionCard from '../../components/cards/AssertionCard'
import Navbar from '../../components/Navbar'
import { makeAuthenticatedServerGETRequest } from '../../utils/helpers'

/** This page displays user's dashboard containing all of their received badges. */
export default function Dashboard () {
  const [BadgeList, setBadgeList] = useState([]) // stores all the badges awarded to the user.

  /** Requests backend for all the badges awarded to the loggedIn user. */
  useEffect(() => {
    makeAuthenticatedServerGETRequest('/assertion/getAssertionByUserId', { })
      .then((res) => {
        if (res.status === 200) {
          setBadgeList(res.data.foundAssertions)
        }
      })
  }, [])

  return (
    <div className='w-full min-h-screen h-max bg-gray-300 '>
      <Navbar selected='/Dashboard' white/>
      <div className=' absolute top-[60px] mx-auto'>
        <div className='flex flex-wrap gap-5 p-10 mx-auto w-full'>
          {!BadgeList.length
            ? <p>No awarded badges...</p>
            : BadgeList.map(data => {
              return <AssertionCard key={data._id} data={data} />
            })}
        </div>
      </div>
    </div>
  )
}
