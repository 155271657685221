import React, { useEffect, useState } from 'react'
import { makeAuthenticatedServerGETRequest, makeAuthenticatedServerRequest, makeServerGETRequest } from '../../utils/helpers'
import Textinput from '../../_atomic-design/molecules/InputMock/TextInputGray'
import DefaultDropdown from '../../_atomic-design/molecules/InputMock/DefaultDropdown'
import AssertionCardAdmin from '../../components/cards/AssertionCardAdmin'
import FilterBar from '../../containers/General/FilterBar'

/** Page to award a new badge. */
export default function AwardBadge () {
  // recipientId,badgeClassId,name,image,evidence,narrative
  const [recipientList, setRecipientList] = useState([]) // store list of recipients
  const [badgeClassList, setBadgeClassList] = useState([]) // store list of badgeClasses
  const [recipientId, setRecipientId] = useState('') // stores list of recipients
  const [badgeClassId, setBadgeClassId] = useState('') // stores selected badgeClass
  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [evidence, setEvidence] = useState('')
  const [narrative, setNarrative] = useState('')
  const [AssertionList, setAssertionList] = useState([])
  const [searchBadgeName, setSearchBadgeName] = useState('')
  const [searchRecipientName, setSearchRecipientName] = useState('')
  const [searchIssuerName, setSearchIssuerName] = useState('')

  /** Format options to pass to dropdown component */
  const badgeClassOptions = badgeClassList.map((item) => ({ value: item._id, text: item.name }))
  const recipientOptions = recipientList.map((item) => ({ value: item._id, text: item.name }))

  /** Function to get all assertions from database. */
  useEffect(() => {
    makeAuthenticatedServerGETRequest('/assertion/getAssertionAll', { })
      .then((res) => {
        if (res.status === 200) {
          setAssertionList(res.data.foundAssertions)
        }
      })
  }, [])

  /** Function to get all recipients and badgeClasses from the database */
  useEffect(() => {
    makeServerGETRequest('/recipient/getRecipients', {})
      .then((res) => {
        if (res.status === 200) {
          setRecipientList(res.data.recipients)
        }
      })
    makeServerGETRequest('/BadgeClass/getBadgeClasses', {})
      .then((res) => {
        if (res.status === 200) {
          setBadgeClassList(res.data.badgeClasses)
        } else {
          console.log('')
        }
      })
  }, [])

  function recipientChangehandler (e) {
    setRecipientId(e.target.value)
  }

  function badgeClassChangeHandler (e) {
    setBadgeClassId(e.target.value)
  }

  function clearData () {
    setRecipientId('')
    setBadgeClassId('')
    setName('')
    setImage('')
    setEvidence('')
    setNarrative('')
    window.location.reload()
  }

  function submitButtonHandler () {
    makeAuthenticatedServerRequest('/assertion/createAssertion', 'POST', { recipientId, badgeClassId, name, image, evidence, narrative })
      .then((res) => {
        if (res.status === 200) {
          alert('saved')
          clearData()
          window.location.reload()
        } else {
          alert(res.data.error.message)
        }
      })
  }

  function deleteButtonHandler (id) {
    console.log('deleting')
    const consent = window.confirm('Are you sure you want to delete this awarded badge ? Delete only if there has been some mistake in awarding badge.')
    if (!consent) {
      return
    }
    makeAuthenticatedServerRequest('/assertion/deleteAssertion', 'delete', { id })
      .then((res) => {
        if (res.status === 200) {
          alert('Deleted')
          window.location.reload()
        } else {
          alert('Some error occured')
        }
      })
  }

  return (
    <div className='flex flex-col justify-around sm:flex-row mx-auto w-full min-h-screen bg-gray-200 relative pt-[100px] sm:pt-[80px]'>
      <div className='flex flex-col sm:flex-row order-2 sm:order-1'>
        <div className='flex flex-col my-2'>
          <div className='font-bold text-xl'>Search Awarded Badges</div>
          <div className='flex flex-row sm:flex-col'>
          <div className='bg-white my-2 p-2 rounded-lg'>
          <div>Badge Name</div>
          <FilterBar search={searchBadgeName} setSearch={setSearchBadgeName} />
        </div>
        <div className='bg-white my-2 p-2 rounded-lg'>
          <div>Recipient Name</div>
          <FilterBar search={searchRecipientName} setSearch={setSearchRecipientName} />
        </div>
        <div className='bg-white my-2 p-2 rounded-lg'>
          <div>Issuer Name</div>
          <FilterBar search={searchIssuerName} setSearch={setSearchIssuerName} />
        </div>
          </div>
      </div>
        <div className='flex flex-col h-full sm:h-[90vh] mx-2 overflow-scroll rounded-lg bg-blue-200 p-5'>
          <div>Awarded Badges</div>
        {AssertionList.map((assertion) => {
          if (assertion.badge.name.toLowerCase().includes(searchBadgeName.toLocaleLowerCase()) &&
            assertion.recipient.name.toLowerCase().includes(searchRecipientName.toLocaleLowerCase()) &&
            assertion.badge.issuer.name.toLowerCase().includes(searchIssuerName.toLocaleLowerCase())) {
            return <AssertionCardAdmin key={assertion._id} data={assertion} onDelete={deleteButtonHandler} />
          }
        })}
      </div>
      </div>
      <div className=' flex flex-col order-1 sm:order-2 p-10 w-max mx-auto sm:mx-0 h-max bg-white rounded-lg text-start space-y-5'>
        <div className='text-xl font-bold mx-auto w-max'>Award new Badge</div>
        <DefaultDropdown label='Recipient' onChange={recipientChangehandler} options={recipientOptions} />
        <DefaultDropdown label='Badge' onChange={badgeClassChangeHandler} options={badgeClassOptions} />
        {/* <Textinput label='Name' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Name' />

        <Textinput label='Image URL' value={image} onChange={(e) => { setImage(e.target.value) }} placeholder='Image url' />

        <Textinput label='Evidence' value={evidence} onChange={(e) => { setEvidence(e.target.value) }} placeholder='Evidence' /> */}

        <Textinput label='Details * ' value={narrative} onChange={(e) => { setNarrative(e.target.value) }} placeholder='Short Description about badge.' />

        <button className='p-1 px-2 border rounded-lg bg-cyan-200' onClick={submitButtonHandler}>Submit</button>
      </div>
    </div>
  )
}
