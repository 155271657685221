import React from 'react'
export default function AdminDashboardGroupTable ({ GroupList, onClick }) {
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL

  return <table className=" border-separate ">
    <thead className='border-lime-700 bg-red-400'>
      <tr className='border-black '>
        <th className=''>No.</th>
        <th className=''>Group Name</th>
        <th className=''>Badge</th>
        <th className=''>Created On</th>
        <th className=''>Issued By</th>
        <th className=''>Total Recipients</th>
        <th className=''>View</th>
      </tr>
    </thead>
    <tbody>
      {
        GroupList.map((group, index) => {
          return <tr key={group._id} className='border-2 border-red-400 my-1 '>
            <td className='bg-violet-700 w-max p-2'>{index + 1}</td>
            <td className='bg-green-200 w-max p-2'>{group.name}</td>
            <td className='bg-emerald-500 w-max p-2'>{group.badgeClass.name}</td>
            <td className='bg-blue-400 w-max p-2'>{new Date(group.createdAt).toLocaleDateString()}</td>
            <td className='bg-yellow-500 w-max p-2'>{group.badgeClass.issuer.name}</td>
            <td className='bg-cyan-500 w-max p-2'>{group.participants.length}</td>
            <td className='bg-lime-500 w-max p-2'><button onClick={() => onClick(group._id)} >Details</button></td>
          </tr>
        })
      }
    </tbody>
  </table>
}
